import { MatSort, MatSortable } from '@angular/material/sort';
import { ModalAlocarEquipeComponent } from './modal-alocar-equipe/modal-alocar-equipe.component';
import { Component, OnInit, ViewChild , EventEmitter, Output} from '@angular/core';
import { BaseFormComponent } from 'src/app/shared/components/base-form/base-form.component';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { FormControl, FormGroup, Validators  } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { AuthService } from 'src/app/services/auth.service';
import { ModalGraficoDistribuicaoComponent } from './modal-grafico-distribuicao/modal-grafico-distribuicao.component';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { ICriterioPaaModel } from 'src/app/models/criterio-paa.model';
import { IPaaUnidadeGestoraModel } from 'src/app/models/paa-unidade-gestora.model';
import Swal from 'sweetalert2';
import { Location } from '@angular/common';
import { FiltroModel } from 'src/app/models/filtro.model';
import { MatPaginator } from '@angular/material/paginator';
import { IPaaDadosBasicosModel } from 'src/app/models/paa-dados-basicos.model';
import { PeriodoService } from 'src/app/services/periodo.service';
import { IPeaRankingPrioridadeModel } from 'src/app/models/pea-ranking-prioridade.model';
import { DateHelper } from 'src/app/core/helpers/date.helper';

  //MODELS
  import { IPaaModel } from 'src/app/models/paa.model';
  import { IPaaCoordenacaoAuditorModel } from 'src/app/models/paa-coordenacao-auditor.model';
  import { IPaaDistribuicaoModel } from 'src/app/models/paa-distribuicao.model';
  import { ITipoAuditoriaModel } from 'src/app/models/tipo-auditoria.model';
  import { IRelatoriaCapituloModel } from 'src/app/models/relatoria-capitulo.model';
  import { ITipoEnfaseModel } from 'src/app/models/tipo-enfase.model';
  import { ICargaCriterioPeaModel } from 'src/app/models/carga-criterio-pea.model';
  import { IPaaInformacoesBasicasModel } from 'src/app/models/paa-informacoes-basicas.model';
      

  //COMPONENTS
  import { ModalConfirmarReativacaoDistribuicaoComponent } from './modal-confirmar-reativacao-distribuicao/modal-confirmar-reativacao-distribuicao.component';
  import { ModalSelecionarUGComponent } from './modal-selecionar-ug/modal-selecionar-ug.component';
  import { ModalSelecionarColunasComponent } from './modal-selecionar-colunas/modal-selecionar-colunas.component';

  //SERVICES
  import { PaaService } from 'src/app/services/paa.service';
  import { TipoAuditoriaService } from 'src/app/services/tipo-auditoria.service';
  import { UnidadeGestoraService } from 'src/app/services/unidade-gestora.service';
  import { TipoDispensaService } from 'src/app/services/tipo-dispensa.service';
  import { CoordenacaoService } from 'src/app/services/coordenacao.service';
  import { CriterioService } from 'src/app/services/criterio.service';
  import { IndicadorService } from 'src/app/services/indicador.service';
  import { TipoEnfaseService } from 'src/app/services/tipo-enfase.service';
  import { PeaService } from 'src/app/services/pea.service';
  


@Component({
  selector: 'app-paa-elaborar',
  templateUrl: './paa-elaborar.component.html',
  styleUrls: ['./paa-elaborar.component.scss'],
})
export class PaaElaborarComponent extends BaseFormComponent implements OnInit {

  @Output() public abaEmitter: EventEmitter<number> = new EventEmitter<number>();
  public paaInformacoesBasicas: IPaaInformacoesBasicasModel = {} as IPaaInformacoesBasicasModel;
  public peaHomologado = false;
    
  public modelDadosBasicos: IPaaDadosBasicosModel = {} as IPaaDadosBasicosModel;
  
  public ugDataSource = new MatTableDataSource<IPaaUnidadeGestoraModel>([]);  
  
  public model: IPaaModel = {} as IPaaModel;
  public ugSemDados = true;
  public criteriosIndicadoresSemDados = true;
  public criterioSemDados = true;
  public tituloAbas : string;

  
  public semDadosRankingEmpresaPublica = false;
  public semDadosRanking = false;
  public rankingPrioridadeEmpresasPublicasDataSource = new MatTableDataSource<IPeaRankingPrioridadeModel>([]);
  public rankingPrioridadeDataSource = new MatTableDataSource<IPeaRankingPrioridadeModel>([]);
  public visualizarBotaoFormulario = false;
  public criterioDataSource = new MatTableDataSource<ICargaCriterioPeaModel>([]);

  pisoAlto: string;
  tetoAlto: string;
  pisoMedio: string;
  tetoMedio: string;
  pisoBaixo: string;
  tetoBaixo: string;
  
  public disalibilitarinput = false;

  public relatoriaDados: IRelatoriaCapituloModel[] = null;
  public visualizarRelatoria = false;

  public aba = 1;
  public salvarDadosPendente = false;
  public paa : IPaaModel = {} as IPaaModel;
  public tiposAuditorias: ITipoAuditoriaModel[];
  public tiposEnfase: ITipoEnfaseModel[];
  public usuarioCoordenadorPaa = false;
  public paaHomologado = false;
  public historico:boolean;

  public nomeTitulo: string;

  public filtroEnfase = {} as FiltroModel;

  public form = new FormGroup({
    nome: new FormControl('', Validators.required),
    //periodoId: new FormControl(null, Validators.required),
    dataInicio: new FormControl(''),
    dataFim: new FormControl(''),
    //indicadorId: new FormControl(null, Validators.required),
    peaId: new FormControl(''),
    origem: new FormControl('', Validators.required),
    subcapitulo: new FormControl(''),
    origemAuditoria: new FormControl({ value: '', disabled: true }),
  });

  @ViewChild('tabela1') public tabela1: MatSort;
  @ViewChild('tabela2') public tabela2: MatSort;
    
  @ViewChild('tabela5') public tabela5: MatSort;

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;  

  constructor(
    route: ActivatedRoute,
    toastr: ToastrService,
    router: Router,
    localeService: BsLocaleService,
    private _location: Location,
    private paaService: PaaService,
    private peaService: PeaService,
    private unidadeGestoraService: UnidadeGestoraService,
    private tipoDispensaService: TipoDispensaService,
    private coordenacaoService: CoordenacaoService,
    private tipoAuditoriaService: TipoAuditoriaService,
    private periodoService: PeriodoService,
    public matDialog: MatDialog,
    public criterioService: CriterioService,
    public authService: AuthService,
    public indicadorService: IndicadorService,
    private activatedRoute: ActivatedRoute,
    private tipoEnfaseService: TipoEnfaseService,
    private dateHelper: DateHelper,
  ) {
    super(route, toastr, router, localeService, matDialog);


    if (this.model) {
      this.form = new FormGroup({
        id: new FormControl({ value: '', disabled: true }),
        nome: new FormControl('', Validators.required),
        //numeroOpa: new FormControl({ value: '', disabled: true }),
        origemAuditoria: new FormControl({ value: '', disabled: true }),
        //indicadorId: new FormControl(null, Validators.required),
        peaId: new FormControl(null, Validators.required),
        dataInicio: new FormControl('', Validators.required),
        dataFim: new FormControl('', Validators.required),
        /* dataElaboracao: new FormControl({ value: '', disabled: true }),
        tipoAuditoriaId: new FormControl('', Validators.required),*/
        unidadeGestoraNome: new FormControl({ value: '' }),
        unidadeGestoraId: new FormControl({ value: '' }),
        /*dataInicioPrevista: new FormControl('', Validators.required),
        dataFimPrevista: new FormControl('', Validators.required),
        objeto: new FormControl('', Validators.required),
        dataInicioRealizacao: new FormControl(''),
        dataFimRealizacao: new FormControl(''),
        auditorCoordenacaoId: new FormControl('', Validators.required),
        notaRodape: new FormControl(''),
        tipoEnfaseId: new FormControl('') */
      });
    }


  }

  config: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    sanitize: false,
    height: '15rem',
    minHeight: '5rem',
    placeholder: '',
    translate: 'yes',
    defaultParagraphSeparator: 'p',
    defaultFontName: 'Arial',
    toolbarHiddenButtons: [
      // ['bold']
    ],
    customClasses: [
      {
        name: 'quote',
        class: 'quote',
      },
      {
        name: 'redText',
        class: 'redText',
      },
      {
        name: 'titleText',
        class: 'titleText',
        tag: 'h1',
      },
    ],
  };

  async ngOnInit() {

    this.aba = 0;
    this.buscar()
  }

  

  

  private atualizarModel(values: any) {
    Object.assign(this.model, values);    
  }

  
  

  public async buscarInformacoesBasicas(){

    await this.paaService.buscarInformacoesBasicas(this.id)
      .then((res)=> {
        if(res.sucesso)
          this.paaInformacoesBasicas = res.dados;
      })
  }

  public async buscar() {
    
    await this.buscarInformacoesBasicas();
    this.atribuirTitulo();
    
  }

  

  private verificarUsuarioCoordenador(paa: IPaaModel) {
    this.usuarioCoordenadorPaa = false;

    if (this.authService.possuiPerfis(['Coordenador'])) {
      this.coordenacaoService
        .obterCoordenacoesCoordenador()
        .then((resCoordenacoes) => {
          let encontrou = false;

          if (resCoordenacoes.dados && resCoordenacoes.dados.length > 0) {
            resCoordenacoes.dados.forEach(function eachCoordenacao(
              coordenacao
            ) {
              paa.paaCoordenacao.forEach(function eachCoordenacaoPaa(
                coordenacaoPaa
              ) {
                if (coordenacao.id === coordenacaoPaa.coordenacaoId) {
                  encontrou = true;
                }
              });
            });
            this.usuarioCoordenadorPaa = encontrou;
          }
        });
    }
  }


  groupByColumns: string[] = [];
  columnsLabel: any[] = [
    {
      field: 'criterio',
      label: 'Indicador/Critério',
    },
    {
      field: 'unidadeMedida',
      label: 'Unidade de medida',
    },
    {
      field: 'tipoEntrada',
      label: 'Tipo de Entrada',
    },
  ];
  columns: any[] = [
    {
      field: 'criterio',
      label: 'Critério',
    },
    {
      field: 'unidadeMedida',
      label: 'Unidade de medida',
    },
    {
      field: 'tipoEntrada',
      label: 'Tipo de Entrada',
    },
    {
      field: 'acoes',
    },
    {
      field: 'actions',
    },
  ];
  _alldata: ICriterioPaaModel[];
  displayedColumns: string[];


  public async mudarAba(ind: number) {
    if (this.novoRegistro || (this.aba === 0 && this.salvarDadosPendente)) {
      this.exibirMensagemErro('Por favor, salve os dados antes de prosseguir');

      return;
    }

    this.aba = ind;
    await this.buscar();
  }

  public getnomeAuditores(auditores: IPaaCoordenacaoAuditorModel[]): string {
    return auditores.map((e) => e.nomeAuditor).join(',');
  }

  public cancelar() {
    this._location.back();
  }

  

  public agrupadorCriterio(index: number, item: any): boolean {
    return item.grupo;
  }

  public subTotalCriterio(index: number, item: any): boolean {
    return item.subTotal;
  }

  

  public async verificarDuplicidadeUG(
    distribuicao: number,
    unidadeGestoraId: number
  ) {
    const res = await this.paaService.obterPorId(distribuicao);
    if (!res.dados.paaUnidadeGestora || res.dados.paaUnidadeGestora.length <= 0) return true;

    const duplicada = res.dados.paaUnidadeGestora?.filter(
      (_) => _.unidadeGestoraId == unidadeGestoraId
    );
    if (duplicada?.length == 0) {
      return true;
    }

    var obrigatoria = duplicada?.filter(
      (_) => _.unidadeGestora.auditoriaObrigatoria == true
    );
    if (obrigatoria?.length > 0 && obrigatoria?.length < 2) {
      return true;
    }

    return false;
  }

  public async incluirUG() {

    this.unidadeGestoraService.obter({ pagina: 0, itensPorPagina: 10000 } as FiltroModel)
      .then((res) => {
        if (res.sucesso) {
          const dialogConfig = new MatDialogConfig();
          dialogConfig.id = 'modal-component';
          dialogConfig.width = '650px';
          dialogConfig.maxHeight = '80vh';
          dialogConfig.hasBackdrop = true;
          dialogConfig.disableClose = true;
          // dialogConfig.data = res.dados.resultado.filter(
          //   (_) => !this.distribuicaoDataSource.data.find((d) => _.id === d.id)
          // );
          const modal = this.matDialog.open(
            ModalSelecionarUGComponent,
            dialogConfig
          );

          modal.afterClosed().subscribe(async (data) => {
            const dialogConfigJustificativa = new MatDialogConfig();
            dialogConfigJustificativa.id = 'modal-component';
            dialogConfigJustificativa.width = '650px';
            dialogConfigJustificativa.hasBackdrop = true;
            dialogConfigJustificativa.disableClose = true;
            dialogConfigJustificativa.data = {titulo: "Tem certeza que deseja incluir a UG?"};
            const modalJustificativa = this.matDialog.open(
              ModalConfirmarReativacaoDistribuicaoComponent,
              dialogConfig
            );

            modalJustificativa.afterClosed().subscribe((item) => {
              if (data && item) {
                const distribuicao = {} as IPaaDistribuicaoModel;
                distribuicao.ativo = true;
                distribuicao.paaId = this.paa.id;
                distribuicao.unidadeGestoraId = data.id;
                distribuicao.manual = true;
                distribuicao.justificativa = item;
                const verificarDuplicidade = this.verificarDuplicidadeUG(
                  distribuicao.paaId,
                  distribuicao.unidadeGestoraId
                );

                if (verificarDuplicidade) {
                  (async () => {
                    const result = await this.paaService.adicionarDistribuicao(distribuicao);
                    try {
                      if (result.sucesso) {
                        this.exibirMensagemSucesso('Unidade Gerencial adicionada com sucesso');
                        await this.buscar();
                      } else {
                        this.exibirMensagemErro(result.mensagem.descricao);
                      }
                    } catch (error) {
                      this.exibirMensagemErro(error.mensagem.descricao);
                    } finally {
                      //this.obterPainelDistribuicao();
                      //this.obterAlocacoesEquipes();
                    }
                  })()
                } else {
                  this.exibirMensagemErro('Essa UG já está incluida');
                }
              }
            });
          });
        } else {
          this.exibirMensagemErro(res.mensagem.descricao);
        }
      })
      .catch((err) => {
        this.exibirMensagemErro(err.mensagem.descricao);
      })
  }

  public async redirectTo(uri: string) {
    this.router
      .navigateByUrl('/', { skipLocationChange: true })
      .then(() => this.router.navigate([uri]));
  }

  

  

  

  // public async obterTiposAuditorias() {
  //   return this.tipoAuditoriaService.obter('', true).then((res) => {
  //     this.tiposAuditorias = res?.dados.filter(a => {
  //       const res = this.capacidadeDataSource.data.find(b => a?.coordenacaoId === b?.coordenacaoId);
  //       if (res?.coordenacaoId) {
  //         return a;
  //       }
  //     });
  //   });
  // }

  public async obterTiposEnfase(){
    this.filtroEnfase.itensPorPagina = 99;
    this.filtroEnfase.ativo = true;
    return this.tipoEnfaseService.obter(this.filtroEnfase).then((res) =>
    {
      this.tiposEnfase = res?.dados.resultado;
    })
  }

  

  
  public lowerCase(nome: string) {
    return nome.toLowerCase().replace(/(?:^|\s)\S/g, (X) => X.toUpperCase());
  }
  

  addGroups(data: any[], groupByColumns: string[]): any[] {
    const rootGroup = new Group();
    rootGroup.expanded = true;
    return this.getSublevel(data, 0, groupByColumns, rootGroup);
  }

  groupBy(key) {
    return function group(array) {
      return array.reduce((acc, obj) => {
        const property = obj[key];
        acc[property] = acc[property] || [];
        acc[property].push(obj);
        return acc;
      }, {});
    };
  }

  getSublevel(
    data: any[],
    level: number,
    groupByColumns: string[],
    parent: Group
  ): any[] {
    if (level >= groupByColumns.length) {
      return data;
    }

    const currentColumn = groupByColumns[level];

    const groupByCurrentColumn = this.groupBy(currentColumn);

    const groups = this.uniqueBy(
      Object.entries(groupByCurrentColumn(data))
        .map(([k, v]) => ({ [k]: v }))
        .map((row) => {
          const result = new Group();
          result.level = level + 1;
          result.parent = parent;
          result.data = row[Object.keys(row)[0]][0];
          for (let i = 0; i <= level; i++) {
            result[groupByColumns[i]] = Object.keys(row)[0];
          }
          return result;
        }),
      JSON.stringify
    );

    let subGroups = [];
    groups.forEach((group) => {
      const rowsInGroup = data.filter(
        (row) => group[currentColumn] === row[currentColumn]
      );
      group.totalCounts = rowsInGroup.length;
      const subGroup = this.getSublevel(
        rowsInGroup,
        level + 1,
        groupByColumns,
        group
      );
      subGroup.unshift(group);
      subGroups = subGroups.concat(subGroup);
    });
    return subGroups;
  }

  uniqueBy(a, key) {
    const seen = {};
    return a.filter((item) => {
      const k = key(item);
      return seen.hasOwnProperty(k) ? false : (seen[k] = true);
    });
  }

  isGroup(index, item): boolean {
    return item.level;
  }

  

  private compare(a, b, isAsc) {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }

  

  
  

  public async obterDados() {    
  }

  

  public exibirRespostas(id: number) {    
  }

  public preencherFormularioCriterio(id: number) {    
  }

  public importarArquivoCriterio(id: number) {    
  }

  public atribuirTitulo(){
    if (this.novoRegistro) {
      this.titulo = 'Novo PAAC';
      this.tituloAbas = "Novo PAAC";
    } else if (this.visualizar) {
      this.titulo = 'Visualizar PAAC - ' + this.paaInformacoesBasicas.nome;
      this.tituloAbas = 'Visualizar PAAC - ' + this.paaInformacoesBasicas.nome;
    } else {
      this.titulo = 'Editar PAAC - ' + this.paaInformacoesBasicas.nome;
      this.tituloAbas = 'Editar PAAC - ' + this.paaInformacoesBasicas.nome;
    }
  }

  public setSubmit(value: boolean){
    this.submit = value;
  }

  public editarColunas() {

    this.paaService
      .obterPainelDistribuicaoColunas(this.paaInformacoesBasicas.id)
      .then((res) => {
        if (res.sucesso) {
          const dialogConfig = new MatDialogConfig();
          dialogConfig.id = 'modal-component';
          dialogConfig.width = '650px';
          dialogConfig.hasBackdrop = true;
          dialogConfig.disableClose = true;
          dialogConfig.data = res.dados;
          const modal = this.matDialog.open(
            ModalSelecionarColunasComponent,
            dialogConfig
          );

          modal.afterClosed().subscribe((data) => {
            if (data) {
              this.paaService
                .AlterarSatusDistribuicaoColunas(data)
                .then((resAssociar) => {
                  if (resAssociar.sucesso) {
                    Swal.fire({
                      toast: true,
                      position: 'top-end',
                      icon: 'success',
                      text: 'Colunas alteradas com sucesso',
                      showConfirmButton: false,
                      timer: 2000,
                      timerProgressBar: true,
                    });
                  } else {
                    this.exibirMensagemAlerta(resAssociar.mensagem.descricao);
                  }
                })
                .catch((errAssociar) => {
                  this.exibirMensagemAlerta(errAssociar.mensagem.descricao);
                })
                .finally(() => {
                  //this.obterPainelDistribuicao();
                });
            }
          });
        } else {
          Swal.fire({
            toast: true,
            position: 'top-end',
            icon: 'error',
            text: res.mensagem.descricao,
            showConfirmButton: false,
            timer: 2000,
            timerProgressBar: true,
          });
        }
      })
      .catch((err) => {
        this.exibirMensagemErro(err.mensagem.descricao);
      })
  }

  public obterTipoTrabalho(){
    const idTipoTrabalhoPaac = 2;
    const idTipoTrabalhoPaacEspecial = 8;
    const idOrigemEspecial = 2;

    return this.paaInformacoesBasicas.origem == idOrigemEspecial ? idTipoTrabalhoPaacEspecial : idTipoTrabalhoPaac;
  }

}

export class Group {
  level = 0;
  parent: Group;
  data: any;
  expanded = true;
  totalCounts = 0;
  get visible(): boolean {
    return !this.parent || (this.parent.visible && this.parent.expanded);
  }
}
