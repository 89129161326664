import { Component, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { defaultDataAcessor } from 'src/app/core/helpers/mat-sort-data-accessor';
import { EnumAcaoRecurso } from 'src/app/models/enum/acao-recurso.enum';
import { EnumRecursoPerfil } from 'src/app/models/enum/recurso-perfil.enum';
import { IPermissaoModel } from 'src/app/models/permissao.model';
import { AuthService } from 'src/app/services/auth.service';
import { FiltroModel } from 'src/app/models/filtro.model';
import { BaseListComponent } from 'src/app/shared/components/base-list/base-list.component';
import Swal from 'sweetalert2';
import { ITipoUniversoModel } from 'src/app/models/tipo-universo.model';
import { IFiltroTipoUniverso } from 'src/app/models/filtros/filtro-tipo-universo.model';
import { IIndicadorModel } from 'src/app/models/indicador.model';
import { IndicadorService } from 'src/app/services/indicador.service';
import { TipoUniversoService } from 'src/app/services/tipo-universo.service';

const sortingDataAccessor = (item, property) => {
  switch (property) {
    case 'quantDiasPadrao':
      return item.quantidadeDiasPadrao;
    case 'nome': return item.nome.trim().toLowerCase();    
    default:
      return item[property];
  }
};

@Component({
  selector: 'app-tipo-universo-list',
  templateUrl: './tipo-universo-list.component.html',
  styleUrls: ['./tipo-universo-list.component.scss']
})
export class TipoUniversoListComponent extends BaseListComponent {
  public dataSource = new MatTableDataSource<ITipoUniversoModel>([]);
  public selectedModel: ITipoUniversoModel;
  public displayedColumns: string[] = ['selecao', 'nome','tipoDescription', 'indicador', 'ativo'];
  public searchNome: string;
  public searchAtivo = true;
  public semDados = true;
  public permissoes: IPermissaoModel;
  public filtro = {} as IFiltroTipoUniverso;
  public totalItens: number;
  public pageEvent: any;
  public indicadores: IIndicadorModel[];

  @ViewChild('TableOnePaginator', { static: true }) tableOnePaginator: MatPaginator;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  constructor(
    private tipoUniversoService: TipoUniversoService,
    private toastr: ToastrService,
    private indicadorService: IndicadorService,
    private router: Router,
    private authService: AuthService
  ) {
    super();
  }

  ngOnInit(): void {
    this.setarPermissoes();
  }

  private setarPermissoes(): void {
    this.permissoes = new IPermissaoModel();
    this.permissoes.consultar = this.authService.possuiPermissao(EnumRecursoPerfil.TipoUniversoAuditavel, EnumAcaoRecurso.Consultar);
    this.permissoes.editar = this.authService.possuiPermissao(EnumRecursoPerfil.TipoUniversoAuditavel, EnumAcaoRecurso.Editar);
    this.permissoes.excluir = this.authService.possuiPermissao(EnumRecursoPerfil.TipoUniversoAuditavel, EnumAcaoRecurso.Excluir);
    this.permissoes.inativarReativar = this.authService.possuiPermissao(EnumRecursoPerfil.TipoUniversoAuditavel, EnumAcaoRecurso.AtivarInativar);
    this.permissoes.incluir = this.authService.possuiPermissao(EnumRecursoPerfil.TipoUniversoAuditavel, EnumAcaoRecurso.Inserir);
    this.permissoes.visualizar = this.authService.possuiPermissao(EnumRecursoPerfil.TipoUniversoAuditavel, EnumAcaoRecurso.Visualizar);
    this.permissoes.imprimir = this.authService.possuiPermissao(EnumRecursoPerfil.TipoUniversoAuditavel, EnumAcaoRecurso.Imprimir);
 }

  async ngAfterViewInit() {
    await this.configurarFiltro();
    this.buscar();
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
    this.dataSource.sortingDataAccessor = sortingDataAccessor;

    if(!this.permissoes.consultar){
      this.exibirMensagemErro("Você não possui permissão para Consulta.");
      this.router.navigate(['/']);
    }
  }

  public novo() {
    
    if(!this.permissoes.incluir){
      this.exibirMensagemErro("Você não possui permissão para esta ação.");
      return;
    }

    this.router.navigate(['/tipo-universo/novo']);
  }

  public atualizarFiltroStatus(valor) {
    this.filtro = {} as IFiltroTipoUniverso;
    this.searchAtivo = valor;
    this.filtro.ativo = this.searchAtivo;
    this.buscar();
  }

  public buscar() {
    this.selectedModel = null;

    this.tipoUniversoService
      .obter(this.filtro)
      .then((res) => {
        if(res.sucesso){
          this.totalItens = res.dados.totalItens;
          this.dataSource = new MatTableDataSource<ITipoUniversoModel>(res.dados.resultado);
          this.totalItens = res.dados.totalItens;
          this.paginator = this.tableOnePaginator;
          this.dataSource.sort = this.sort;
          this.dataSource.sortingDataAccessor = sortingDataAccessor;
          this.dataSource._updateChangeSubscription();
          this.semDados = this.dataSource.filteredData.length === 0;
          this.configurarFiltro();
        }
      }).catch((err) => {
        this.exibirMensagemErro(err);
      })
  }

  public reativar(id: number) {
    if(!this.permissoes.inativarReativar){
      this.exibirMensagemErro("Você não possui permissão para esta ação.");
      return;
    }

    this.tipoUniversoService
      .reativar(id)
      .then((res) => {
        if (res.sucesso) {
          this.exibirMensagemSucesso('Tipo de universo auditável reativado com sucesso.');
        } else {
          this.exibirMensagemAlerta(res.mensagem.descricao);
        }
      })
      .catch((err) => {
        this.exibirMensagemErro(err);
      })
      .finally(() => {
        this.buscar();
      });
  }

  public inativar(id: number) {
    if(!this.permissoes.inativarReativar){
      this.exibirMensagemErro("Você não possui permissão para esta ação.");
      return;
    }

    this.tipoUniversoService
      .inativar(id)
      .then((res) => {
        if (res.sucesso) {
          this.exibirMensagemSucesso('Tipo de universo auditável inativado com sucesso.');
        } else {
          this.exibirMensagemAlerta(res.mensagem.descricao);
        }
      })
      .catch((err) => {
        this.exibirMensagemErro(err);
      })
      .finally(() => {
        this.buscar();
      });
  }

  public editar(id: number) {
    if(!this.permissoes.editar){
      this.exibirMensagemErro("Você não possui permissão para esta ação.");
      return;
    }

    this.router.navigate([`/tipo-universo/${id}`]);
  }

  public selecionar(item: any) {
    this.selectedModel = !this.selectedModel || this.selectedModel.id !== item.id ? item : null;
  }

  public excluir(id: number) {
    if(!this.permissoes.excluir){
      this.exibirMensagemErro("Você não possui permissão para esta ação.");
      return;
    }

    this.tipoUniversoService
      .excluir(id)
      .then((res) => {
        if (res.sucesso) {
          this.exibirMensagemSucesso("Tipo de universo auditável excluído com sucesso.");
        } else {
          this.exibirMensagemAlerta(res.mensagem.descricao);
        }
      })
      .catch((err) => {
        this.exibirMensagemErro(err);
      })
      .finally(() => {
        this.buscar();
      });
  }

  public visualizar(id: number) {
    if(!this.permissoes.visualizar){
      this.exibirMensagemErro("Você não possui permissão para esta ação.");
      return;
    }

    this.router.navigate([`/tipo-universo/${id}/visualizar`]);
  }

  public imprimir(id: number){
    if(!this.permissoes.imprimir){
      this.exibirMensagemErro("Você não possui permissão para esta ação.");
      return;
    }
  }

  private async configurarFiltro() {
    
    await this.indicadorService.obter('', undefined, true).then(res => {
      if(res.sucesso){
        this.indicadores = res.dados;
      }
    });

    this.filtro.pagina = this.tableOnePaginator.pageIndex + 1;
    this.filtro.itensPorPagina = this.tableOnePaginator.pageSize;
    this.filtro.colunaOrder = this.sort.active;
    this.filtro.ativo = this.searchAtivo;
    this.filtro.pesquisa = this.searchNome;
    this.filtro.direcao = this.sort.direction;
  }
  sortData(sort) {
    this.filtro.colunaOrder = sort.active;
    this.filtro.direcao = sort.direction;

    this.buscar();
}

public selecionarIndicador(element: IIndicadorModel){
  if(element){
    this.filtro.idIndicador = element.id;
  }else{
    this.filtro.idIndicador = null;
  }
  this.buscar();
}

public selecionarClassificacao(id: number){
  if(id){
    this.filtro.tipo = id;
  }else{
    this.filtro.tipo = null;
  }
  this.buscar();
}

  pageChanged(e) {
    this.filtro.pagina = e.pageIndex + 1;
    e.pageIndex = this.filtro.pagina;
    this.filtro.itensPorPagina = e.pageSize;
    this.buscar();
  }
}
