import { Injectable } from "@angular/core";
import { BaseService } from "./base.service";
import { HttpClient } from "@angular/common/http";
import { LocationStrategy } from "@angular/common";
import { IBaseModel } from "../models/base.model";
import { IUniversoAuditavelModel } from "../models/universo-auditavel.model";
import { PaginacaoModel } from "../models/paginacao.model";
import { filterBy } from "../core/helpers/filterby";
import { FiltroUniversoAuditavel } from "../models/filtro-universo-auditavel";
import { UniversAuditavelListaModel } from "../models/univers-auditavel-lista.model";
import { IArquivoModel } from "../models/arquivo.model";
import { IResponsavelModel } from "../models/responsaveis.model";

@Injectable({
  providedIn: 'root',
})


export class UniversoAuditavelService extends BaseService{
  constructor(httpClient: HttpClient, locationStrategy: LocationStrategy) {
    super(httpClient, locationStrategy);
  }

  public obter(filtro: FiltroUniversoAuditavel): Promise<IBaseModel<PaginacaoModel<UniversAuditavelListaModel[]>>> {
    return this.httpClient
      .get<IBaseModel<PaginacaoModel<UniversAuditavelListaModel[]>>>(filterBy(filtro,`${this.apiBaseUrl}/universoauditavel/obter`))
      .toPromise();
  }
  public async obterPorId(id: number): Promise<IBaseModel<IUniversoAuditavelModel>>{
    return this.httpClient
      .get<IBaseModel<IUniversoAuditavelModel>>(`${this.apiBaseUrl}/UniversoAuditavel/obterPorId/${id}`)
      .toPromise();
  }

  public async solicitarPreenchimentoQuestionario(id: number, qtdeDiasPrazo: number): Promise<IBaseModel<void>> {
    return this.httpClient
      .post<IBaseModel<void>>(`${this.apiBaseUrl}/UniversoAuditavel/${id}/${qtdeDiasPrazo}/solicitarPreenchimento`, {})
      .toPromise();
  }

  public async Avocar(id: number): Promise<IBaseModel<void>> {
    return this.httpClient
      .post<IBaseModel<void>>(`${this.apiBaseUrl}/UniversoAuditavel/${id}/avocar`, {})
      .toPromise();
  }

  public async excluir(id: number): Promise<IBaseModel<void>> {
    return this.httpClient
      .delete<IBaseModel<void>>(`${this.apiBaseUrl}/UniversoAuditavel/${id}`, {})
      .toPromise();
  }
  public async inserir(data: IUniversoAuditavelModel): Promise<IBaseModel<IUniversoAuditavelModel>>{
    return this.httpClient
      .post<IBaseModel<IUniversoAuditavelModel>>(`${this.apiBaseUrl}/UniversoAuditavel/inserir`, data)
      .toPromise();
  }
  public async inserirUniversoAPartirDoUltimo(idUG: number, idTipoUniversoAuditavel: number): Promise<IBaseModel<void>> {
    return this.httpClient
      .post<IBaseModel<void>>(`${this.apiBaseUrl}/UniversoAuditavel/${idUG}/${idTipoUniversoAuditavel}/inserirCopia`, {})
      .toPromise();
  }

  public async atualizar(data: IUniversoAuditavelModel): Promise<IBaseModel<IUniversoAuditavelModel>>{
    return this.httpClient
      .put<IBaseModel<IUniversoAuditavelModel>>(`${this.apiBaseUrl}/tipoUniversoAuditavel`, data)
      .toPromise();
  }

  public async enviarArquivo(idUG: number, idTipoUniversoAuditavel: number, anexo : IArquivoModel): Promise<IBaseModel<void>> {
    const formData: FormData = new FormData();

    formData.append('id', anexo.id.toString());
    formData.append('nomeArquivo', anexo.nomeArquivo);
    formData.append('entidadeArquivo', anexo.entidadeArquivo.toString());
    formData.append('arquivo', anexo.arquivo);

    return this.httpClient
      .post<IBaseModel<void>>(`${this.apiBaseUrl}/UniversoAuditavel/${idUG}/${idTipoUniversoAuditavel}/enviarArquivo`,formData, {})
      .toPromise();
  }

  public async DownloadArquivo(idUG: number,  idTipoUniverso: number): Promise<IBaseModel<IArquivoModel>> {
    return this.httpClient
      .post<IBaseModel<IArquivoModel>>(`${this.apiBaseUrl}/UniversoAuditavel/${idUG}/${idTipoUniverso}/obterArquivo`, {})
      .toPromise();
  }

  public async encerrarUniversoImportacaoArquivo(idUG: number, idTipoUniverso: number): Promise<IBaseModel<boolean>> {
    return this.httpClient
      .post<IBaseModel<boolean>>(`${this.apiBaseUrl}/UniversoAuditavel/${idUG}/${idTipoUniverso}/0/encerrar-avaliacao-arquivo-importado`,null, {})
      .toPromise();
  }

  public async obterCriterioPorTipo(idUG: number , idTipoUniverso: number ): Promise<IBaseModel<any[]>> {
    return this.httpClient
      .get<IBaseModel<IResponsavelModel[]>>(
        `${this.apiBaseUrl}/UniversoAuditavel/montaComboUniversoAuditavel/${idUG}/${idTipoUniverso}`
      )
      .toPromise();
  }

  public finalizarUniversoAuditavel(
    id: number
  ): Promise<IBaseModel<any>> {
    return this.httpClient
      .put<IBaseModel<boolean>>(
        `${this.apiBaseUrl}/UniversoAuditavel/finalizarUniversoAuditavel/${id}`,
        {}
      )
      .toPromise();
  }

}
