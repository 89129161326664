import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { IRelatoriaCapituloModel } from 'src/app/models/relatoria-capitulo.model';
import { IRelatoriaModel } from 'src/app/models/relatoria.model';
import { RelatoriaService } from 'src/app/services/relatoria.service';
import { Location } from '@angular/common';
import { BaseFormComponent } from '../../base-form/base-form.component';
import { ToastrService } from 'ngx-toastr';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';
import { MatDialog } from '@angular/material/dialog';
import { AprovacaoService } from 'src/app/services/aprovacao.service';
import { AvaliacaoService } from 'src/app/services/avaliacao.service';
import { IBaseModel } from 'src/app/models/base.model';


@Component({
  selector: 'app-imprimir-linha-do-tempo',
  templateUrl: './imprimir-linha-do-tempo.component.html',
  styleUrls: ['./imprimir-linha-do-tempo.component.scss']
})

export class imprimirLinhaDoTempoComponent extends BaseFormComponent implements OnInit {
  relatoriaDados: IRelatoriaCapituloModel[];
  public dadosObtidos: IRelatoriaModel;
  visualizarRelatoriaHidden = false;
  tipoTrabalho;
  datas: any;
  activatedRoute: any;
  @Output() public abaRelatoria: EventEmitter<number> = new EventEmitter<number>();
  @Output() public voltarAbaRelatoria: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(
    route: ActivatedRoute,
    toastr: ToastrService,
    router: Router,
    localeService: BsLocaleService,
    private relatoriaService: RelatoriaService,
    private aprovacaoService: AprovacaoService,
    private avaliacaoService: AvaliacaoService,
    public matDialog: MatDialog,
  ) {
    super(route, toastr, router, localeService, matDialog);
  }

  ngOnInit(): void {

    this.obter();
  }

  public async obter() {
    let res: IBaseModel<IRelatoriaModel>;
    
    if (this.id) {

  
        const avaliacao = await this.aprovacaoService.imprimirRelatoria(this.id);
        res = {
          sucesso: avaliacao.sucesso,
          dados: JSON.parse(avaliacao.dados),
          mensagem: avaliacao.mensagem,
          resultadoValidacao: avaliacao.resultadoValidacao
        } as IBaseModel<IRelatoriaModel>;
        const tipoTrabalho = this.route.snapshot.paramMap.get('tipoTrabalho');
        switch (tipoTrabalho) {
          case 'pea':
            this.tipoTrabalho = 1;
            break;
          case 'paa':
            this.tipoTrabalho = 2;
            break;
          case 'opa':
            this.tipoTrabalho = 3;
            break;
          case 'osa':
            this.tipoTrabalho = 4;
            break;
          case 'planejamento':
            this.tipoTrabalho = 7;
            break;
        }  
        
      if (res.sucesso) {        
        this.relatoriaDados = res.dados.relatoriasCapitulos;
        this.dadosObtidos = res.dados;
        var tipoTrabalhoId = this.dadosObtidos.tipoTrabalhoId

        const resultado = await this.avaliacaoService.obter(this.tipoTrabalho, tipoTrabalhoId);
        this.datas = resultado.dados.datas;
      }
      else {
        this.toastr.error(res.mensagem.descricao, 'Atenção');
        return null;
      }
    }
    this.visualizarRelatoriaHidden = true;
  }

  print(): void {
    window.print();
  }

  backClicked() {
    window.close()
  }

  VoltarRelatoria() {
    this.abaRelatoria.emit(5);
    this.voltarAbaRelatoria.emit(true);
    this.router.navigate(['/pea/', this.id]);    
  }
}
