import { CdkDrag, CdkDragDrop, moveItemInArray, transferArrayItem } from "@angular/cdk/drag-drop";
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MagnitudesEscalaRiscoService } from 'src/app/services/magnitudes-escala-risco.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-grid-magnitude-escala',
  templateUrl: './grid-magnitude-escala.component.html',
  styleUrls: ['./grid-magnitude-escala.component.scss']
})
export class GridMagnitudeEscalaComponent implements OnInit {

  @Input() visualizar: boolean = false;
  @Output() updatedData = new EventEmitter<any>();

  escalaId: any;
  magnitudeList = [
    { id: '1', nome: 'Muito Baixa' },
    { id: '2', nome: 'Baixa' },
    { id: '3', nome: 'Media' },
    { id: '4', nome: 'Alta' },
    { id: '5', nome: 'Muito Alta' }
  ]
  criteriosAvaliacaoRiscosId: any;
  dataSource = new MatTableDataSource<any>([]);
  selectedModel: any;
  displayedColumns: string[] = [
    "avaliacao",
    "valorDoRisco",
    "actions",
  ];

  listData: any[] = []

  searchNome: string;
  searchAtivo = true;
  semDados = true;
  constructor(
    private service: MagnitudesEscalaRiscoService
  ) { }

  ngOnInit(): void {
  }

  excluir(e: any) {
    Swal.fire({
      title: "Excluir",
      text: "Tem certeza que deseja excluir esse registro?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Sim",
      cancelButtonText: "Não",
    }).then(async (result) => {
      if (result.value) {
        this.service.excluir(e.id).then(() => {
          this.getMagnitudeByEscala()
        })
        return;
      }
      return;
    });
  }

  setEscalaId(escalaId: number) {
    this.escalaId = escalaId
    this.getMagnitudeByEscala()
  }

  getMagnitudeName(e: any) {
    return this.magnitudeList.find(o => o.id == e)?.nome
  }

  getMagnitudeByEscala() {
    this.service.obter(this.escalaId, true, true).then((res) => {
      // this.dataSource = new MatTableDataSource(res.dados);
      // this.dataSource._updateChangeSubscription();
      // this.semDados = this.dataSource.filteredData.length === 0;
      this.semDados = res.dados.length === 0;
      this.updatedData.emit(res.dados)
      this.listData = res.dados
    })
  }

  onDrop(event: CdkDragDrop<string[]>) {
    if (event.previousContainer === event.container) {
      moveItemInArray(this.listData, event.previousIndex, event.currentIndex);
    }

    this.listData.forEach((element, idx) => {
      element.ordem = (idx + 1).toString();
      this.service.atualizar(element)
    })

  }

}
