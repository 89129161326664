import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { ICriteriosAvaliacaoRiscos } from 'src/app/models/criterios-avaliacao-riscos.model';
import { EnumAcaoRecurso } from 'src/app/models/enum/acao-recurso.enum';
import { EnumRecursoPerfil } from 'src/app/models/enum/recurso-perfil.enum';
import { FiltroModel } from 'src/app/models/filtro.model';
import { IPermissaoModel } from 'src/app/models/permissao.model';
import { AuthService } from 'src/app/services/auth.service';
import { CriteriosAvaliacaoRiscoService } from 'src/app/services/criterios-avaliacao-risco.service';
import { FormulaRiscoResidualService } from 'src/app/services/formula-risco-residual.service';
import { MagnitudesEscalaRiscoService } from 'src/app/services/magnitudes-escala-risco.service';
import { NiveisRiscoInerenteService } from 'src/app/services/niveis-risco-inerente.service';
import { NiveisRiscoResidualService } from 'src/app/services/niveis-risco-residual.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-criterios-avaliacao-list',
  templateUrl: './criterios-avaliacao-list.component.html',
  styleUrls: ['./criterios-avaliacao-list.component.scss']
})
export class CriteriosAvaliacaoListComponent implements OnInit {

  public dataSource = new MatTableDataSource<ICriteriosAvaliacaoRiscos>([]);
  public selectedModel: ICriteriosAvaliacaoRiscos;
  public displayedColumns: string[] = [
    "selecao",
    "nome",
    "descricao",
    "ativo",
  ];
  public searchNome: string;
  public searchAtivo = true;
  public semDados = true;
  public permissoes: IPermissaoModel;
  public filtro = {} as FiltroModel;
  public totalItens: number;
  public pageEvent: any;

  @ViewChild('TableOnePaginator', { static: true }) tableOnePaginator: MatPaginator;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  constructor(
    private authService: AuthService,
    private router: Router,
    private criteriosAvaliacaoService: CriteriosAvaliacaoRiscoService,
  ) { }

  async ngOnInit() {
    this.setarPermissoes();
    await this.buscar();
    this.dataSource.sort = this.sort;
  }

  public novo() {
    this.router.navigate(['/criteriosAvaliacao/novo']);
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
  }

  async buscar() {
    this.selectedModel = null;
    this.criteriosAvaliacaoService
      .obter(this.filtro.pesquisa, this.filtro.ativo, false)
      .then((res) => {
        this.dataSource = new MatTableDataSource<any>(res.dados);
        this.paginator = this.tableOnePaginator;
        this.paginator.length = res.dados.length;
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;
        this.dataSource._updateChangeSubscription();
        this.semDados = this.dataSource.filteredData.length === 0;
      })
      .catch((err) => {
        this.exibirMensagemErro(err.mensagem);
      });
  }

  private setarPermissoes(): void {
    this.permissoes = new IPermissaoModel();
    this.permissoes.consultar = this.authService.possuiPermissao(
      EnumRecursoPerfil.CriteriosAvaliacaoRiscos,
      EnumAcaoRecurso.Consultar
    );
    this.permissoes.editar = this.authService.possuiPermissao(
      EnumRecursoPerfil.CriteriosAvaliacaoRiscos,
      EnumAcaoRecurso.Editar
    );
    this.permissoes.excluir = this.authService.possuiPermissao(
      EnumRecursoPerfil.CriteriosAvaliacaoRiscos,
      EnumAcaoRecurso.Excluir
    );
    this.permissoes.inativarReativar = this.authService.possuiPermissao(
      EnumRecursoPerfil.CriteriosAvaliacaoRiscos,
      EnumAcaoRecurso.AtivarInativar
    );
    this.permissoes.incluir = this.authService.possuiPermissao(
      EnumRecursoPerfil.CriteriosAvaliacaoRiscos,
      EnumAcaoRecurso.Inserir
    );
    this.permissoes.visualizar = this.authService.possuiPermissao(
      EnumRecursoPerfil.CriteriosAvaliacaoRiscos,
      EnumAcaoRecurso.Visualizar
    );
  }

  editar(id: any) {
    this.router.navigate(['/criteriosAvaliacao/', id]);
  }

  reativar(id: any) {
    this.criteriosAvaliacaoService.reativar(id).then(() => {
      this.buscar()
    })
  }

  inativar(id: any) {
    this.criteriosAvaliacaoService.inativar(id).then(() => {
      this.buscar()
    })
  }

  excluir(id: any) {
    this.criteriosAvaliacaoService.excluir(id).then(() => {
      this.buscar()
    })
  }

  visualizar(id: any) {
    this.router.navigate([`/criteriosAvaliacao/${id}/visualizar`]);
  }

  pageChanged(e: any) {
    this.filtro.pagina = e.pageIndex + 1;
    e.pageIndex = this.filtro.pagina;
    this.filtro.itensPorPagina = e.pageSize;
    this.buscar();
  }

  sortData(sort) {
    this.filtro.colunaOrder = sort.active;
    this.filtro.direcao = sort.direction;

    this.buscar();
  }

  public selecionar(item: any) {
    this.selectedModel =
      !this.selectedModel || this.selectedModel.id !== item.id
        ? item
        : null;
  }

  public exibirMensagemErro(mensagem: string) {
    Swal.fire({
      toast: true,
      position: 'top-end',
      icon: 'error',
      text: mensagem,
      showConfirmButton: false,
      showCloseButton: true,
    });
  }
}
