import { Component, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from 'src/app/services/auth.service';
import { OpaService } from 'src/app/services/opa.service';
import { ActivatedRoute, Router } from '@angular/router';
import { BaseFormComponent } from 'src/app/shared/components/base-form/base-form.component';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { IOpaModel } from 'src/app/models/opa.model';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ITipoAuditoriaModel } from 'src/app/models/tipo-auditoria.model';
import { TipoAuditoriaService } from 'src/app/services/tipo-auditoria.service';
import { UnidadeGestoraService } from 'src/app/services/unidade-gestora.service';
import { CoordenacaoService } from 'src/app/services/coordenacao.service';
import { IAuditorCoordenacaoModel } from 'src/app/models/auditor-coordenacao.model';
import { SubtipoAuditoriaService } from 'src/app/services/subtipo-auditoria.service';
import { ISubtipoAuditoriaModel } from 'src/app/models/subtipo-auditoria.model';
import { DateHelper } from 'src/app/core/helpers/date.helper';
import { IBaseModel } from 'src/app/models/base.model';
import { IOpaSubtipoAuditoriaModel } from 'src/app/models/opa-subtipo-auditoria.model';
import { ModalAlocarEquipeOpaComponent } from '../modal/modal-alocar-equipe/modal-alocar-equipe-opa.component';
import { IOpaEquipeModel } from 'src/app/models/opa-equipe.model';
import { IOpaEquipeAuditorModel } from 'src/app/models/opa-equipe-auditor.model';
import { PaaService } from 'src/app/services/paa.service';
import { ModalConfirmarInativacaoComponent } from 'src/app/shared/components/modal-confirmar-inativacao/modal-confirmar-inativacao.component';
import { IOpaHistoricoModel } from 'src/app/models/opa-historico.model';
import Swal from 'sweetalert2';
import { IRelatoriaCapituloModel } from 'src/app/models/relatoria-capitulo.model';
import { ITipoEnfaseModel } from 'src/app/models/tipo-enfase.model';
import { TipoEnfaseService } from 'src/app/services/tipo-enfase.service';
import { FiltroModel } from 'src/app/models/filtro.model';

@Component({
  selector: 'app-opa-form',
  templateUrl: './opa-form.component.html',
  styleUrls: ['./opa-form.component.scss']
})
export class OpaFormComponent extends BaseFormComponent implements OnInit {
  public model: IOpaModel = {} as IOpaModel;
  public tiposAuditoria = [] as ITipoAuditoriaModel[];
  public tiposEnfase = [] as ITipoEnfaseModel[];
  public coordenadoresAuditoria = [] as IAuditorCoordenacaoModel[];
  public subtipoDataSource = new MatTableDataSource<IOpaSubtipoAuditoriaModel>([]);
  public auditoresDataSource = new MatTableDataSource<IOpaEquipeAuditorModel>([]);
  public subtipoSemDados = true;
  public auditoresSemDados = true;
  public historicoTipoAuditoria: IOpaHistoricoModel = {} as IOpaHistoricoModel;
  public historicoUnidadeGestora: IOpaHistoricoModel = {} as IOpaHistoricoModel;
  public tipoAuditorioSelecionado: ITipoAuditoriaModel = {} as ITipoAuditoriaModel;
  public permiteEdicao = false;
  public emPlanejamento = false;
  public aba: number = 0;
  public versao = false;
  public diasUteisAuditoria: any;

  public relatoriaDados: IRelatoriaCapituloModel[] = null;

  public visualizarRelatoria = false;
  public form = new FormGroup({
    id: new FormControl({ value: '', disabled: true }),
    numeroOpa: new FormControl({ value: '', disabled: true }),
    origemAuditoria: new FormControl({ value: '', disabled: true }),
    dataElaboracao: new FormControl({ value: '', disabled: true }),
    tipoAuditoriaId: new FormControl('', Validators.required),
    unidadeGestoraNome: new FormControl({ value: '' }),
    unidadeGestoraId: new FormControl('', Validators.required),
    dataInicioPrevista: new FormControl(''),
    dataFimPrevista: new FormControl(''),
    objeto: new FormControl('', Validators.required),
    dataInicioRealizacao: new FormControl('', Validators.required),
    dataApresentacaoPlanejamento: new FormControl('', Validators.required),
    dataFimRealizacao: new FormControl(''),
    auditorCoordenacaoId: new FormControl('', Validators.required),
    notaRodape: new FormControl(''),
    tipoEnfaseId: new FormControl(''),
    qtdeDiasUteis: new FormControl('')
  });

  constructor(route: ActivatedRoute,
    toastr: ToastrService,
    router: Router,
    localeService: BsLocaleService,
    matDialog: MatDialog,
    private opaService: OpaService,
    private tipoAuditoriaService: TipoAuditoriaService,
    private coordenacaoService: CoordenacaoService,
    private ugService: UnidadeGestoraService,
    private subtipoAuditoriaService: SubtipoAuditoriaService,
    private paaService: PaaService,
    private dateHelper: DateHelper,
    public authService: AuthService,
    public tipoEnfaseService: TipoEnfaseService,
    private activatedRoute: ActivatedRoute,
  ) {
    super(route, toastr, router, localeService, matDialog);

    if (this.novoRegistro) {
      this.titulo = 'Nova OPA';
      this.versao = true;
    } 
    if (this.router.url.includes("nova-versao")) {
      this.versao = true
    }
  }

  async ngOnInit() {

    this.obterTiposAuditoria();
    this.obterTiposEnfase();
    this.obterCoordenadoresAuditoria();
    if (this.novoRegistro) {
      this.gerarNova();
    } else {
      this.obterOpa();
    }
    const numeroAba = this.activatedRoute.snapshot.queryParamMap.get('aba');
    if (numeroAba) { this.mudarAba(Number(numeroAba)); }
    this.router.url.includes('aba=6') ? this.mudarAba(6) : null;
    this.router.url.includes('aba=7') ? this.mudarAba(7) : null;
  }

  public gerarNova() {
    this.opaService
      .gerarNova()
      .then((res) => {
        if (res.sucesso) {
          this.model = res.dados;
          this.permiteEdicao = true;
          this.atualizarModel();
          this.atualizarForm(true);
        } else {
          this.exibirMensagemErro(res.mensagem.descricao);
        }
      })
      .catch((err) => {
        this.exibirMensagemErro(err.mensagem.descricao);
      })
  }

  public obterOpa() {
    this.opaService
      .obterPorId(this.id)
      .then((res) => {
        if (res.sucesso) {
          this.model = res.dados;
          this.form.controls['qtdeDiasUteis'].setValue(this.model.qtdeDiasUteis)
          this.form.controls['dataFimRealizacao'].setValue(this.model.dataFimRealizacao)
          if (this.visualizar) {
            this.titulo = 'Visualizar OPA - ' + (this.model.numero ? this.model.numero : '');
          } else {
            this.titulo = 'Editar OPA - ' + (this.model.numero ?  this.model.numero : '');
            this.versao = true;
      
            if (this.model.paa) {
              this.form = new FormGroup({
                id: new FormControl({ value: '', disabled: true }),
                numeroOpa: new FormControl({ value: '', disabled: true }),
                origemAuditoria: new FormControl({ value: '', disabled: true }),
                dataElaboracao: new FormControl({ value: '', disabled: true }),
                tipoAuditoriaId: new FormControl('', Validators.required),
                unidadeGestoraNome: new FormControl({ value: '' }),
                unidadeGestoraId: new FormControl('', Validators.required),
                dataInicioPrevista: new FormControl('', Validators.required),
                dataFimPrevista: new FormControl('', Validators.required),
                objeto: new FormControl('', Validators.required),
                dataInicioRealizacao: new FormControl(''),
                dataFimRealizacao: new FormControl(''),
                auditorCoordenacaoId: new FormControl('', Validators.required),
                notaRodape: new FormControl(''),
                tipoEnfaseId: new FormControl(''),
                qtdeDiasUteis: new FormControl({ value: '', disabled: true})
              });
            }
          }

          this.tipoAuditorioSelecionado = this.model.tipoAuditoria;
          this.permiteEdicao = !this.visualizar && this.model.ativo && this.model.situacao.codigo === 1;
          this.emPlanejamento = this.model.situacao.codigo === 4;
          this.atualizarModel();
          this.atualizarSubtipos();
          this.atualizarAuditores();
          this.atualizarForm(false);
          this.atualizarHistoricoTipoAuditoria();
          this.atualizarHistoricoUnidadeGestora();
        } else {
          this.exibirMensagemErro(res.mensagem.descricao);
        }
      })
      .catch((err) => {
        this.exibirMensagemErro(err.mensagem.descricao);
      })
  }

  public obterTiposAuditoria() {
    this.tipoAuditoriaService
      .obterAtivos()
      .then((res) => {
        if (res.sucesso) {
          this.tiposAuditoria = res.dados;
        } else {
          this.exibirMensagemErro(res.mensagem.descricao);
        }
      })
      .catch((err) => {
        this.exibirMensagemErro(err.mensagem.descricao);
      })
  }

  public obterTiposEnfase(){
    const filtroEnfase = {} as FiltroModel;
    filtroEnfase.itensPorPagina = 99;
    filtroEnfase.ativo = true;
    this.tipoEnfaseService.obter(filtroEnfase)
    .then((res) => {
      if(res.sucesso){
        this.tiposEnfase = res.dados.resultado
      }else{
        this.exibirMensagemErro(res.mensagem.descricao);
      }
    })
    .catch((err) =>
    {
      this.exibirMensagemErro(err.mensagem.descricao);
    })
  }

  public obterCoordenadoresAuditoria() {
    this.coordenacaoService
      .obterCoordenadoresAtivos()
      .then((res) => {
        if (res.sucesso) {
          this.coordenadoresAuditoria = res.dados;
        } else {
          this.exibirMensagemErro(res.mensagem.descricao);
        }
      })
      .catch((err) => {
        this.exibirMensagemErro(err.mensagem.descricao);
      })
  }

  public obterCoordenadorTipoAuditoria(tipoAuditoriaId: number) {
    this.tipoAuditoriaService
      .obterCoordenadorTipoAuditoria(tipoAuditoriaId)
      .then((res) => {
        if (res.sucesso) {
          this.atualizarFormCoordenadorTipoAuditoria(res.dados.id);
        } else {
          this.exibirMensagemErro(res.mensagem.descricao);
        }
      })
      .catch((err) => {
        this.exibirMensagemErro(err.mensagem.descricao);
      })
  }

  public async onSubmit(salvarEFechar: boolean = false) {
    this.submit = true;

    if (this.form.invalid) {
      this.exibirMensagemAlerta('Formulário inválido!');
      return;
    }

    if (this.model.subtiposAuditoria.length === 0) {
      this.exibirMensagemAlerta('Informe algum Subtipo de Auditoria');
      return;
    }

    if (!this.model.opaEquipe || this.model.opaEquipe.auditores.length === 0) {
      this.exibirMensagemAlerta('Informe a Equipe de Auditores');
      return;
    }
    this.atualizarModelForm(this.form.value);

    try {
      let res: IBaseModel<IOpaModel> = null;

      if (!this.novoRegistro) {
        this.atualizarHistoricoOpa();
        res = await this.opaService.atualizar(this.model);
      } else {
        res = await this.opaService.inserir(this.model);
      }

      if (res.sucesso) {
        this.exibirMensagemSucesso(res.mensagem.descricao);
        this.historicoTipoAuditoria = {} as IOpaHistoricoModel;
        this.historicoUnidadeGestora = {} as IOpaHistoricoModel;
        if (salvarEFechar == true) {
          this.router.navigate(['/opa/']);
        }else
        if(this.novoRegistro){
          this.router.navigate([`/opa/${res.dados.id}`]);
          await this.ngOnInit();
        }else window.location.reload()
      } else {
        const validacoes = this.montarMensagensValidacao(res);
        if (validacoes.trim().length > 0) {
          this.exibirMensagemAlerta(validacoes, true);
        } else {
          this.exibirMensagemAlerta(res.mensagem.descricao);
        }
      }
    } catch (err) {
      this.exibirMensagemErro(err.mensagem.descricao);
    }
  }

  public onSair() {
    window.history.back();
  }

  public async onSelectTipoAuditoria(evt) {
    if (!this.novoRegistro) {
      const modal = this.recuperarConfiguracaoModalHistorico('Tem certeza que alterar o Tipo de Auditoria?');    

      modal.afterClosed().subscribe((justificativa) => {
        if (justificativa) {
          this.model.tipoAuditoriaId = this.form.get('tipoAuditoriaId').value;
          this.model.tipoAuditoria = null;
          this.model.auditorCoordenacao = null;
          this.atualizarTipoAuditoriaSelecionado(evt);
          this.obterCoordenadorTipoAuditoria(this.model.tipoAuditoriaId);

          this.historicoTipoAuditoria.tipoAuditoriaId = this.model.tipoAuditoriaId;
          this.atualizarDadosHistorico(this.historicoTipoAuditoria, justificativa);
          this.diasUteisAuditoria = this.tiposAuditoria.find(item => item.id == this.model.tipoAuditoriaId);
          this.form.controls['qtdeDiasUteis'].setValue(this.diasUteisAuditoria.quantidadeDias )
          
        } else {
          evt.target.value = this.model.tipoAuditoriaId;
          this.diasUteisAuditoria = this.tiposAuditoria.find(item => item.id == evt.target.value);
          this.form.controls['qtdeDiasUteis'].setValue(this.diasUteisAuditoria.quantidadeDias )
        }
      });
    } else {
      this.model.tipoAuditoriaId = this.form.get('tipoAuditoriaId').value;
      this.diasUteisAuditoria = this.tiposAuditoria.find(item => item.id == this.model.tipoAuditoriaId);
      this.form.controls['qtdeDiasUteis'].setValue(this.diasUteisAuditoria.quantidadeDias )

      this.atualizarTipoAuditoriaSelecionado(evt);
      await this.obterCoordenadorTipoAuditoria(this.model.tipoAuditoriaId);
    }
  }

  public async exibirModalUnidadeGestora() {
    try {

      const res = await this.ugService.obterAtivos();
      if (res.sucesso) {
        const modal = this.exibirModal('Selecionar Unidade Gestora', res.dados);
        modal.afterClosed().subscribe(data => {
          if (data) {
            if (!this.novoRegistro) {
              const modal = this.recuperarConfiguracaoModalHistorico('Tem certeza que alterar a Unidade Gestora?');

              modal.afterClosed().subscribe((justificativa) => {
                if (justificativa) {
                  this.model.unidadeGestoraId = data.id;
                  this.model.unidadeGestora = null;
                  this.atualizarFormUnidadeGestora(data);

                  this.historicoUnidadeGestora.unidadeGestoraId = this.model.unidadeGestoraId;
                  this.atualizarDadosHistorico(this.historicoUnidadeGestora, justificativa);
                }
              });
            } else {
              this.model.unidadeGestoraId = data.id;
              this.atualizarFormUnidadeGestora(data);
            }
          }
        });
      } else {
        this.exibirMensagemErro(res.mensagem.descricao);
      }
    } catch (err) {
      this.exibirMensagemErro(err.mensagem.descricao);
    }
  }

  public async exibirModalSubtipo() {
    try {

      const res = await this.subtipoAuditoriaService.obterAtivos();
      if (res.sucesso) {
        const modal = this.exibirModalMultiselect('Adicionar Subtipo', res.dados, 'Adicionar');
        modal.afterClosed().subscribe(data => {
          if (data) {
            if (Array.isArray(data)) {
              let subtiposAuditoriaSelecionados = [] as ISubtipoAuditoriaModel[];
              subtiposAuditoriaSelecionados = data.map(d => (d as ISubtipoAuditoriaModel));
              subtiposAuditoriaSelecionados.forEach(x => this.model.subtiposAuditoria.push({ subtipoAuditoriaId: x.id, subtipoAuditoria: x, opaId: this.id } as IOpaSubtipoAuditoriaModel));
            }
            this.atualizarSubtipos();
          }
        });
      } else {
        this.exibirMensagemErro(res.mensagem.descricao);
      }
    } catch (err) {
      this.exibirMensagemErro(err.mensagem.descricao);
    }
  }

  public excluirSubtipo(indice: number) {
    Swal.fire({
      title: "Excluir Registro",
      text: "Tem certeza que deseja excluir esse Registro?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Sim",
      cancelButtonText: "Não",
    }).then((res) => {
      if (res.value) {
        this.subtipoDataSource.data.splice(indice, 1);
        this.subtipoDataSource._updateChangeSubscription();
        this.subtipoSemDados = this.subtipoDataSource.filteredData.length === 0;
      }
    })

  }

  public async exibirModalAlocarEquipe() {
    try {
      const auditoresTipoAuditoria = this.model.tipoAuditoriaId === 0 || this.model.tipoAuditoriaId == undefined ? [] as IAuditorCoordenacaoModel[] : (
        await this.tipoAuditoriaService.obterAuditoresCoordenacaoTipoAuditoria(this.model.tipoAuditoriaId)
      ).dados;
      const dialogConfig = new MatDialogConfig();
      dialogConfig.id = 'modal-component';
      dialogConfig.width = '650px';
      dialogConfig.maxHeight = '80vh';
      dialogConfig.hasBackdrop = true;
      dialogConfig.disableClose = true;
      dialogConfig.data = { equipe: this.model.opaEquipe, auditores: this.coordenadoresAuditoria, tipoAuditoria: this.tipoAuditorioSelecionado, auditoresTipoAuditoria };
      const modal = this.matDialog.open(
        ModalAlocarEquipeOpaComponent,
        dialogConfig
      );
      modal.afterClosed().subscribe((data) => {
        if (data) {
          this.model.opaEquipe = data;
          if (this.model.opaEquipe.auditores.length > 0) {
            this.paaService
              .obterDispensasPeriodo(this.dateHelper.toString(this.model.dataInicioRealizacao), this.dateHelper.toString(this.model.dataFimRealizacao))
              .then((res) => {
                if (res.sucesso) {
                  this.model.opaEquipe.auditores.forEach(auditor => {
                    auditor.indisponivelPeriodo = res.dados.filter(a => a.auditor.auditorId === auditor.auditorId).length > 0;
                  });
                }
              })
              .finally(() => {
                this.atualizarAuditores();
              });
          }
        }
      });
    } catch (err) {
      this.exibirMensagemErro(err.mensagem.descricao);
    }
  }

  public excluirAuditor(indice: number) {
    Swal.fire({
      title: "Excluir Registro",
      text: "Tem certeza que deseja excluir esse Registro?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Sim",
      cancelButtonText: "Não",
    }).then((res) => {
      if (res.value) {
        this.auditoresDataSource.data.splice(indice, 1);
        this.auditoresDataSource._updateChangeSubscription();
        this.auditoresSemDados = this.auditoresDataSource.filteredData.length === 0;
      }
    })

  }

  public enviarAprovacao() {

    this.opaService.enviarAprovacao(this.id)
      .then((res) => {
        if (res.sucesso) {
          this.exibirMensagemSucesso(res.mensagem.descricao);
          this.obterOpa();
          this.router.navigate(['/opa']);
        } else {
          this.exibirMensagemAlerta(res.mensagem.descricao);
        }
      })
      .catch((err) => {
        this.exibirMensagemErro(err.mensagem.descricao);
      })
  }

  public aprovar() {
    this.opaService.aprovar(this.id)
      .then((res) => {
        if (res.sucesso) {
          this.exibirMensagemSucesso(res.mensagem.descricao);
          this.obterOpa();
          return;
        } else {
          this.exibirMensagemAlerta(res.mensagem.descricao);
        }
      })
      .catch((err) => {
        this.exibirMensagemErro(err.mensagem.descricao);
      })
  }


  public async gerarNovaVersao() {
    this.submit = true;

    if (this.form.invalid) {
      this.exibirMensagemAlerta('Formulário inválido!');
      return;
    }

    if (!this.model.opaEquipe || this.model.opaEquipe.auditores.length === 0) {
      this.exibirMensagemAlerta('Informe a Equipe de Auditores');
      return;
    }

    this.atualizarModelForm(this.form.value);

    try {
      const res: IBaseModel<IOpaModel> = await this.opaService.gerarNovaVersao(this.model);;

      if (res.sucesso) {
        this.exibirMensagemSucesso(res.mensagem.descricao);
        this.router.navigate(['/opa']);
      } else {
        const validacoes = this.montarMensagensValidacao(res);
        if (validacoes.trim().length > 0) {
          this.exibirMensagemAlerta(validacoes, true);
        } else {
          this.exibirMensagemAlerta(res.mensagem.descricao);
        }
      }
    } catch (err) {
      this.exibirMensagemErro(err.mensagem.descricao);
    }
  }

  private atualizarModel() {
    if (!this.model.subtiposAuditoria) {
      this.model.subtiposAuditoria = [] as IOpaSubtipoAuditoriaModel[];
    }
    if (!this.model.opaEquipe) {
      this.model.opaEquipe = { auditores: [] as IOpaEquipeAuditorModel[] } as IOpaEquipeModel;
    }
  }

  private atualizarModelForm(values: any) {
    Object.assign(this.model, values);

    this.model.dataInicioPrevista = this.dateHelper.toDatePtBr(this.model.dataInicioPrevista);
    this.model.dataFimPrevista = this.dateHelper.toDatePtBr(this.model.dataFimPrevista);
    this.model.dataInicioRealizacao = this.dateHelper.toDatePtBr(this.model.dataInicioRealizacao);
    this.model.dataFimRealizacao = this.dateHelper.toDatePtBr(this.model.dataFimRealizacao);
    this.model.dataApresentacaoPlanejamento = this.dateHelper.toDatePtBr(this.model.dataApresentacaoPlanejamento);
  }

  private atualizarForm(novo: boolean) {
    this.form.patchValue({
      id: novo ? '' : this.model.id,
      unidadeGestoraNome: novo ? '' : this.model.unidadeGestora.nome,
      unidadeGestoraId: novo ? '' : this.model.unidadeGestora.id,
      numeroOpa: this.model.numero,
      origemAuditoria: this.model.origem.descricao,
      tipoAuditoriaId: novo ? '' : this.model.tipoAuditoriaId,
      dataElaboracao: this.dateHelper.toStringPtBr(this.model.dataElaboracao),
      dataInicioPrevista: this.dateHelper.toStringPtBr(this.model.dataInicioPrevista),
      dataFimPrevista: this.dateHelper.toStringPtBr(this.model.dataFimPrevista),
      dataInicioRealizacao: this.dateHelper.toStringPtBr(this.model.dataInicioRealizacao),
      dataFimRealizacao: this.dateHelper.toStringPtBr(this.model.dataFimRealizacao),
      dataApresentacaoPlanejamento: this.dateHelper.toStringPtBr(this.model.dataApresentacaoPlanejamento),
      objeto: novo ? '' : this.model.objeto,
      auditorCoordenacaoId: novo ? '' : this.model.auditorCoordenacaoId,
      notaRodape: novo ? '' : this.model.notaRodape,
      tipoEnfaseId: novo ? '' : this.model.tipoEnfaseId,
      diasUteisAuditoria: novo ? '' : this.model.diasUteisAuditoria
    });
  }

  private atualizarSubtipos() {
    this.subtipoDataSource = new MatTableDataSource<IOpaSubtipoAuditoriaModel>(this.model.subtiposAuditoria);
    this.subtipoDataSource._updateChangeSubscription();
    this.subtipoSemDados = this.subtipoDataSource.filteredData.length === 0;
  }

  private atualizarAuditores() {
    this.auditoresDataSource = new MatTableDataSource<IOpaEquipeAuditorModel>(this.model.opaEquipe.auditores);
    this.auditoresDataSource._updateChangeSubscription();
    this.auditoresSemDados = this.auditoresDataSource.filteredData.length === 0;
  }

  private atualizarFormUnidadeGestora(unidadeGestora) {
    this.form.patchValue({
      unidadeGestoraNome: unidadeGestora.nome,
      unidadeGestoraId: unidadeGestora.id
    });
  }

  private atualizarFormCoordenadorTipoAuditoria(auditorId: number) {
    this.form.patchValue({
      auditorCoordenacaoId: auditorId
    });
  }

  private recuperarConfiguracaoModalHistorico(titulo: string): MatDialogRef<ModalConfirmarInativacaoComponent, any> {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.id = 'modal-component';
    dialogConfig.width = '650px';
    dialogConfig.hasBackdrop = true;
    dialogConfig.disableClose = true;
    dialogConfig.data = { titulo: titulo };

    return this.matDialog.open(
      ModalConfirmarInativacaoComponent,
      dialogConfig
    );
  }

  private atualizarDadosHistorico(historico: IOpaHistoricoModel, justificativa: string) {
    historico.opaId = this.id;
    historico.justificativa = justificativa;
  }

  private atualizarHistoricoOpa() {
    if (!this.model.historicoAlteracoes) {
      this.model.historicoAlteracoes = [] as IOpaHistoricoModel[];
    }

    if (this.historicoTipoAuditoria.opaId) {
      this.model.historicoAlteracoes.push(this.historicoTipoAuditoria)
    }

    if (this.historicoUnidadeGestora.opaId) {
      this.model.historicoAlteracoes.push(this.historicoUnidadeGestora)
    }
  }

  private atualizarTipoAuditoriaSelecionado(evt) {
    this.tipoAuditorioSelecionado.id = this.model.tipoAuditoriaId;
    this.tipoAuditorioSelecionado.nome = evt.target.options[evt.target.selectedIndex].text;
  }

  

  private atualizarHistoricoTipoAuditoria() {
    let historicos = this.model.historicoAlteracoes.filter(historico => historico.tipoAuditoriaId);
    if (historicos.length > 0) {
      let tooltip = 'Histórico de alteração\n\n';
      historicos.forEach(historico => {
        tooltip += `${historico.nomeUsuario} - ${this.dateHelper.toStringPtBr(historico.data)}, Tipo Auditoria: ${historico.tipoAuditoria.nome}, Justificativa: ${historico.justificativa}\n\n`;
      });
      this.model.historicoTipoAuditoria = tooltip;
    }
  }

  private atualizarHistoricoUnidadeGestora() {
    let historicos = this.model.historicoAlteracoes.filter(historico => historico.unidadeGestoraId);
    if (historicos.length > 0) {
      let tooltip = 'Histórico de alteração\n\n';
      historicos.forEach(historico => {
        tooltip += `${historico.nomeUsuario} - ${this.dateHelper.toStringPtBr(historico.data)}, Unidade Gestora: ${historico.unidadeGestora.sigla}, Justificativa: ${historico.justificativa}\n\n`;
      });
      this.model.historicoUnidadeGestora = tooltip;
    }
  }

  public mudarAba(aba: number) {
    this.aba = aba;
  }
}
