<mat-dialog-content>
    <div class="card card-secondary card-outline">
      <div class="card-header bg-light">
        <h4 class="card-title mb-0">{{titulo}}</h4>
        <div class="card-tools">
          <button type="button" class="btn btn-tool" (click)="cancelar()">
            <i class="fas fa-times"></i>
          </button>
        </div>
      </div>
      <div class="card-body p-0">
        <form [formGroup]="form" (ngSubmit)="salvar()">
          <div class="card-body">
            <!-- Pergunta -->
            <div class="form-group">
                <label for="pergunta" class="mb-1">{{data.pergunta}}</label>
                <input type="hidden" formControlName="resposta" />
                <div
                  class="form-control form-control-sm"
                  [innerHTML]="htmlResposta"
                ></div>
                </div>
            <!-- Avaliação -->
            <div class="form-group">
              <label for="avaliacao" class="mb-1">Sua avaliação</label>
              <select id="avaliacao" formControlName="statusAvaliacao" class="form-control form-control-sm">
                <option *ngFor="let option of opcoesAvaliacao" [value]="option.value">
                  {{ option.label }}
                </option>
              </select>
            </div>
            <!-- Resposta Revisada -->
            <div class="form-group">
              <th> Resposta Revisada </th>
              <ng-container [ngSwitch]="form.get('tipoEntrada').value">
                <div *ngSwitchCase="'Somente texto'" class="example-form-field">
                  <div class="form-group">
                    <angular-editor
                      [config]="config"
                      id="respostaRevisada"
                      formControlName="respostaRevisada"
                      rows="5"
                      class="form-control"
                    ></angular-editor>
                  </div>
                </div>

                <mat-form-field *ngSwitchCase="'Numérico Inteiro'" appearance="outline" class="example-form-field">
                  <input matInput type="number" formControlName="respostaRevisada" />
                </mat-form-field>
                <mat-form-field *ngSwitchCase="'Numérico Decimal'" appearance="outline" class="example-form-field">
                  <label for="respostaRevisada" class="mb-1">Resposta Revisada</label>
                  <input matInput type="number" formControlName="respostaRevisada" />
                </mat-form-field>
                <div *ngSwitchCase="'Texto com anexo opcional'">
                  <div class="form-group">
                    <angular-editor
                      [config]="config"
                      id="respostaRevisada"
                      formControlName="respostaRevisada"
                      rows="5"
                      class="form-control"
                    ></angular-editor>
                  </div>                
                </div>
                <div *ngSwitchCase="'Texto com anexo obrigatório'" >
                  <div class="form-group">
                    <angular-editor
                      [config]="config"
                      id="respostaRevisada"
                      formControlName="respostaRevisada"
                      rows="5"
                      class="form-control"
                    ></angular-editor>
                  </div>                
                </div>
              </ng-container>              

            </div>
          </div>
  
          <div class="card-footer">
            <button type="submit" class="btn btn-primary btn-sm">
              <i class="far fa-save"></i> Salvar
            </button>
            <button type="button" class="btn btn-default btn-sm" (click)="cancelar()">
              <i class="fas fa-times"></i> Cancelar
            </button>
          </div>
        </form>
      </div>
    </div>
  </mat-dialog-content>
  