import { PaaService } from 'src/app/services/paa.service';
import { IPaaCoordenacaoAuditorModel } from 'src/app/models/paa-coordenacao-auditor.model';
import { MatTableDataSource } from '@angular/material/table';
import { IPaaEquipeModel } from './../../../models/paa-equipe.model';
import { ToastrService } from 'ngx-toastr';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CalendarioService } from 'src/app/services/calendario.service';
import {
  Component,
  OnInit,
  Inject,
  ViewChild,
  AfterViewInit,
  ChangeDetectorRef,
} from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort, MatSortable } from '@angular/material/sort';
import { IBaseModel } from 'src/app/models/base.model';
import { DateHelper } from 'src/app/core/helpers/date.helper';
import Swal from 'sweetalert2';
import { Observable, forkJoin, of } from 'rxjs';
import { concatMap } from 'rxjs/operators';

@Component({
  selector: 'app-modal-alocar-equipe',
  templateUrl: './modal-alocar-equipe.component.html',
  styleUrls: ['./modal-alocar-equipe.component.scss'],
})

export class ModalAlocarEquipeComponent implements AfterViewInit, OnInit {
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild('tabela1') public tabela1: MatSort;
  @ViewChild('tabela2') public tabela2: MatSort;

  public model = {} as IPaaEquipeModel;
  public minDate: Date;
  public maxDate: Date;
  public houveSugestaoData: boolean;
  public dataInvalida: boolean;
  public dataFinalSugerida: Date;

  public coordenadorNome: string;
  public msgValidacaoDataInicio: string = "Insira uma data de ínicio.";
  public msgValidacaoDataFim: string = "Insira uma data de fim..";
  public auditorDataSource = new MatTableDataSource<
    IPaaCoordenacaoAuditorModel
  >([]);
  public auditorSemDados = true;
  public auditoresDisponiveisDataSource: IPaaCoordenacaoAuditorModel[];
  public novoAuditor: string;

  public auditorOutraCoordenacaoDataSource = new MatTableDataSource<
    IPaaCoordenacaoAuditorModel
  >([]);
  public auditorOutraCoordenacaoSemDados = true;

  public dataObrigatoria = false;

  public auditoresConflito: IPaaCoordenacaoAuditorModel[];


  constructor(
    private cdRef: ChangeDetectorRef,
    private dialogRef: MatDialogRef<ModalAlocarEquipeComponent>,
    public toastr: ToastrService,
    private paaService: PaaService,
    private dateHelper: DateHelper,
    private calendarioService: CalendarioService,
    @Inject(MAT_DIALOG_DATA) public data
  ) {
    this.model = data?.equipe;
    this.minDate = new Date(data?.ano, 1 - 1, 1);
    this.maxDate = new Date(data?.ano, 12 - 1, 31);

    this.coordenadorNome = this.model?.paaDistribuicao?.tipoAuditoria?.coordenacao?.nome;

    if (this.model.dataInicio != null) {
      this.model.dataInicio = new Date(this.model.dataInicio);
    }

    if (this.model.dataFim != null) {
      this.model.dataFim = new Date(this.model.dataFim);
    }


    // Montar lista de Auditores da mesma coordenacao
    const auditores = this.model.listaAuditores.filter(
      (auditor) =>
        this.model.paaEquipeAuditor.findIndex(
          (a) => a.auditorId === auditor.auditorId
        ) >= 0
    );
    for (const auditor of auditores) {
      this.auditorDataSource.data.push({
        paaId: auditor.paaId,
        coordenacaoId: auditor.coordenacaoId,
        ano: auditor.ano,
        auditorId: auditor.auditorId,
        nomeAuditor: auditor.nomeAuditor,
        nomeCoordenacao: auditor.nomeCoordenacao,
        nomeDispensa: auditor.nomeDispensa,
      });
    }
    this.auditorDataSource.paginator = this.paginator;
    this.auditorDataSource.sort = this.sort;
    this.auditorDataSource.data = this.model.listaAuditores.map(
      (auditor) => ({
        paaId: auditor.paaId,
        coordenacaoId: auditor.coordenacaoId,
        ano: auditor.ano,
        auditorId: auditor.auditorId,
        nomeCoordenacao: auditor?.nomeCoordenacao,
        nomeDispensa: auditor.nomeDispensa,
        nomeAuditor: (auditor.nomeAuditor.charAt(0).toUpperCase() + auditor.nomeAuditor.slice(1)),
        selecionado:
          this.model.paaEquipeAuditor.findIndex(
            (a) => a.auditorId === auditor.auditorId
          ) >= 0,
      })
    );
    this.auditorSemDados = this.auditorDataSource.data.length === 0;
    this.auditorDataSource._updateChangeSubscription();

    this.auditoresDisponiveisDataSource = this.model.listaAuditores.filter(
      (auditor) =>
        auditores.findIndex((a) => a.auditorId === auditor.auditorId) < 0
    );

    // Montar lista de Auditores de outras coordenacoes
    this.auditorOutraCoordenacaoDataSource.paginator = this.paginator;
    this.auditorOutraCoordenacaoDataSource.sort = this.sort;
    this.auditorOutraCoordenacaoDataSource.data = this.model.listaAuditoresConvidados.map(
      (auditor) => ({
        paaId: auditor.paaId,
        coordenacaoId: auditor.coordenacaoId,
        ano: auditor.ano,
        auditorId: auditor.auditorId,
        nomeCoordenacao: auditor?.nomeCoordenacao,
        nomeDispensa: auditor.nomeDispensa,
        nomeAuditor: (auditor.nomeAuditor.charAt(0).toUpperCase() + auditor.nomeAuditor.slice(1)),
        selecionado:
          this.model.paaEquipeAuditor.findIndex(
            (a) => a.auditorId === auditor.auditorId
          ) >= 0,
      })
    );
    this.auditorOutraCoordenacaoSemDados =
      this.auditorOutraCoordenacaoDataSource.data.length === 0;
    this.auditorOutraCoordenacaoDataSource._updateChangeSubscription();
  }

  ngOnInit(): void {

  }


  ngOnChangeDataInicio(event:any): void {
    if(event)
      this.validaData(this.model.dataInicio, true);
    }
  
  ngOnChangeDataFim(event:any): void {
    if(event)
      this.validaData(this.model.dataFim, false);
  }

  public async validaData(dataReferencia: Date, isDataInicial: boolean){
    if(!dataReferencia)
      return false;

    if(dataReferencia.getFullYear() != this.data.ano){
      if(isDataInicial){
        this.msgValidacaoDataInicio = "Ano precisar ser igual ao do PAAC";
      }else{
        this.msgValidacaoDataFim = "Ano precisar ser igual ao do PAAC";
      }
      this.setDataObrigatoria();

      return false;
    }

    const validacao = await this.paaService.VerificaDataAlocacao(this.dateHelper.toString(dataReferencia), isDataInicial, this.model?.paaDistribuicao?.tipoAuditoria?.id);

    if(validacao.sucesso){
      if(validacao.dados && isDataInicial){
        this.houveSugestaoData = true;
        this.dataInvalida = false;
        this.dataFinalSugerida = new Date(validacao.dados);
        this.model.dataFim = new Date(validacao.dados);
      }
    }else{
      this.dataInvalida = true;
      this.exibirMensagemErro(validacao.mensagem.descricao);
    }

  }



  public incluirAuditor() {
    if (this.novoAuditor) {
      this.auditorDataSource.data.push(
        this.auditoresDisponiveisDataSource.find(
          (a) => a.auditorId === this.novoAuditor
        )
      );
      this.auditorDataSource._updateChangeSubscription();
      this.auditoresDisponiveisDataSource = this.model.listaAuditores.filter(
        (auditor) =>
          this.auditorDataSource.data.findIndex(
            (a) => a.auditorId === auditor.auditorId
          ) < 0
      );
      this.novoAuditor = null;
    }
  }

  public excluirAuditor(element: IPaaCoordenacaoAuditorModel) {
    this.auditorDataSource.data.splice(
      this.auditorDataSource.data.findIndex(
        (a) => a.auditorId === element.auditorId
      ),
      1
    );
    this.auditorDataSource._updateChangeSubscription();
    this.auditoresDisponiveisDataSource = this.model.listaAuditores.filter(
      (auditor) =>
        this.auditorDataSource.data.findIndex(
          (a) => a.auditorId === auditor.auditorId
        ) < 0
    );
  }

  ngAfterViewInit(): void {
    this.tabela1.sort({ id: 'nome', start: 'asc' } as MatSortable);
    this.auditorDataSource.sortingDataAccessor = (item, property) => {
      switch (property) {
        case 'nome':
          return item.nomeAuditor;
        default:
          return item[property];
      }
    };
    this.auditorDataSource.sort = this.tabela1;

    this.tabela2.sort({ id: 'nome', start: 'asc' } as MatSortable);
    this.auditorOutraCoordenacaoDataSource.sortingDataAccessor = (
      item,
      property
    ) => {
      switch (property) {
        case 'nome':
          return item.nomeAuditor;
        default:
          return item[property];
      }
    };
    this.auditorOutraCoordenacaoDataSource.sort = this.tabela2;
    this.cdRef.detectChanges();
  }

  public verificarDatas() {
    
    if (this.model.dataInicio == null || this.model.dataFim == null) {
      this.setDataObrigatoria();
      this.exibirMensagemErro('Por favor, preencha os campo com a data incial e data fim');
      return false;
    }
    if(this.model.dataInicio.getFullYear() != this.data?.ano
      || this.model.dataFim.getFullYear() != this.data?.ano){
        this.setDataObrigatoria();
        this.exibirMensagemErro('O ano do período precisar ser igual ao do PAAC.');
        return false;
      }

    return true;
  }

  public setDataObrigatoria() {
    this.dataObrigatoria = true;
  }

  public async concluir() {
    if(this.dataInvalida == true){
      this.exibirMensagemErro("Data inválida");
      return false;
    }

    if(this.houveSugestaoData && this.model.dataFim > this.dataFinalSugerida)
    {
      Swal.fire({
        title: 'Atenção',
        text: "O período informado é maior do que o definido para o tipo de auditoria. Deseja continuar?",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Sim',
        cancelButtonText: 'Não',
      }).then((res) => {
        if (res.value) {
          if (this.verificarDatas()) {
            this.model.listaAuditores = this.auditorDataSource.data.filter(
              (d) => d.selecionado
            );
            this.model.listaAuditoresConvidados = this.auditorOutraCoordenacaoDataSource.data.filter(
              (d) => d.selecionado
            );
      
            this.salvar();
          }
        }
      });
    }else{
      if (this.verificarDatas()) {
        this.model.listaAuditores = this.auditorDataSource.data.filter(
          (d) => d.selecionado
        );
        this.model.listaAuditoresConvidados = this.auditorOutraCoordenacaoDataSource.data.filter(
          (d) => d.selecionado
        );
  
        this.salvar();
      }
    }

  }

  private async salvar() {
    
    const validacao = await this.paaService.validarAlocacaoEquipe(this.model);
    
    const alocadosReq = validacao.dados.alocados.length ?   this.percorrerObjetoComAlertsAlocados(validacao?.dados?.alocados) : of([]);
    const dispensadosReq = validacao.dados.dispensados.length ? this.percorrerObjetoComAlertsDispensados(validacao.dados.dispensados): of([]);
   
    forkJoin({
      alocados: alocadosReq,
      dispensados: dispensadosReq
    }).subscribe(()=> this.atualizarAlocacao());
  }

  percorrerObjetoComAlertsAlocados(validacao: any) {
    const keys = validacao;
    return this.exibirAlertaPorChaveAlocados(keys, 0);
  }

  percorrerObjetoComAlertsDispensados(validacao: any) {
    const keys = validacao;
    return this.exibirAlertaPorChaveDispensados(keys, 0);
  }

  // Função recursiva para exibir os SweetAlerts em sequência
  exibirAlertaPorChaveAlocados(validacao: any, index: number): Observable<void> {
    if (index >= validacao.length) {
      return of(undefined);
    }
    const chaveAtual = validacao[index];
    return (this.validarAlocados(chaveAtual)).pipe(
      concatMap(() => this.exibirAlertaPorChaveAlocados(validacao, index + 1))
    );
  }

  exibirAlertaPorChaveDispensados(validacao: any, index: number): Observable<void> {
    if (index >= validacao.length) {
      return of(undefined);
    }
    const chaveAtual = validacao[index];
    return (this.validarDispensados(chaveAtual)).pipe(
      concatMap(() => this.exibirAlertaPorChaveDispensados(validacao, index + 1))
    );
  }

  private validarAlocados(validacao: any): Observable<any> {
    return new Observable<any>((observer) => {
      
      let text = '';

      //text += `<div class='align-left'>\n O(s) auditor(es) selecionado(s) ${validacao.nomeAuditor}, pertencem a outra coordenação, ${validacao.nomeCoordenacao}. \n </div><br>`
      text += `<div class='align-left'>\n O(s) auditor(es) selecionado(s) ${validacao.nomeAuditor}, já possui alocação para o mesmo período. \n </div><br>`

      Swal.fire({
        title: 'Alocar equipe',
        html: `${text}`,
        icon: 'warning',
        showConfirmButton: false,
        showCancelButton: true,
        cancelButtonText: 'Ok',
        timerProgressBar: true,
      }).then((result) => {
        
        const listaConvidados = this.model.listaAuditoresConvidados.filter(x => x.auditorId !== validacao.auditorId);
          this.model.listaAuditoresConvidados =[...listaConvidados];
          const lista = this.model.listaAuditores.filter(x => x.auditorId !== validacao.auditorId);
          this.model.listaAuditores =[...lista];
          observer.next();
          observer.complete();
      });
    });
  }

  private validarDispensados(validacao: any): Observable<any> {
    return new Observable<any>((observer) => {
      let text = '';

      text += `<div class='align-left'>\n O Auditor selecionado ${validacao.nomeAuditor} não está disponível para período alocado, está com a dispensa ${validacao.nomeDispensa}. \n </div><br>`

      Swal.fire({
        title: 'Alocar equipe',
        html: `${text} <br> <div class='align-left'>\nDeseja continuar ?</div>`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Sim',
        cancelButtonText: 'Não',
        timerProgressBar: true,
      }).then((result) => {
        
        if (result.isConfirmed) {
          observer.next();
          observer.complete();
          this.atulizarListaDispensados(validacao);
        } else {
          // observer.error('Ação cancelada');
          observer.next();
          observer.complete();
        }
      });
    });
  }

  public obterEquipeIndisponivel(): IPaaCoordenacaoAuditorModel[] {
    var auditoresIndisponiveis: IPaaCoordenacaoAuditorModel[] = [] as IPaaCoordenacaoAuditorModel[];

    var concatenarAuditores = [...this.model.listaAuditores.filter(_ => _.selecionado), ...this.model.listaAuditoresConvidados.filter(_ => _.selecionado)]
    var auditoresCoordenacao = this.obterAuditoresCapacidadeTrabalho().reduce((a, b) => a.concat(b));

    concatenarAuditores.map(a => {
      let auditorIndisponivel = auditoresCoordenacao.find(_ => _.coordenacaoId == this.model?.paaDistribuicao?.tipoAuditoria?.coordenacao?.id && a.auditorId === _.auditorId)
      auditorIndisponivel?.auditorId ? null : auditoresIndisponiveis.push(a);
    })

    return auditoresIndisponiveis;
  }

  public async validarEquipeDisponivel() {
    const auditoresIndisponiveis = this.obterEquipeIndisponivel();

    let quantidade = auditoresIndisponiveis.length > 1;

    if (auditoresIndisponiveis.length > 0) {
      let text = quantidade ?
        `O(s) auditor(es) selecionado(s) ${auditoresIndisponiveis.map(_ => _.nomeAuditor).join(', ')} não estão disponíveis para auditoria.` :
        `O auditor selecionado ${auditoresIndisponiveis.map(_ => _.nomeAuditor).join(', ')} não esta disponível para auditoria.`
      Swal.fire({
        title: 'Alocar equipe',
        text: `${text} \nDeseja continuar ?`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Sim',
        cancelButtonText: 'Não',
      }).then(res => {
        if (res.value) {
          this.atualizarAlocacao()
        }
      }).catch();
    } else {
      this.atualizarAlocacao()
    }
  }

  private atualizarAlocacao() {
    this.paaService
      .atualizarAlocacaoEquipe(this.model)
      .then((res) => {
        if (res.sucesso) {
          res.dados.alertas.forEach((alerta) => {
            Swal.fire({
              toast: true,
              position: 'top-end',
              icon: 'warning',
              text: alerta,
              showConfirmButton: false,
              timer: 2000,
              timerProgressBar: true,
            })
          });
          Swal.fire({
            toast: true,
            position: 'top-end',
            icon: 'success',
            text: 'Registro salvo com sucesso',
            showConfirmButton: false,
            timer: 2000,
            timerProgressBar: true,
          })
          this.dialogRef.close(this.model);
        } else {
          const validacoes = this.montarMensagensValidacao(res);
          Swal.fire({
            toast: true,
            position: 'top-end',
            icon: 'warning',
            title: res.mensagem.descricao,
            text: validacoes,
            showConfirmButton: false,
            timer: 2000,
            timerProgressBar: true,
          })
        }
      })
      .catch((err) => {
        this.exibirMensagemErro(err.mensagem.descricao);
      })
  }

  private atulizarListaAlocacao(alocado: any){
    this.model.listaAuditoresConvidados.push(alocado);
  }

  private atulizarListaDispensados(dispensado: any){
    this.model.listaAuditores.push(dispensado);
  }

  public cancelar() {
    this.dialogRef.close(false);
  }

  private montarMensagensValidacao(model: IBaseModel<any>) {
    let validacaoTexto = '';

    if (model.resultadoValidacao) {
      validacaoTexto = '';
      for (const val of model.resultadoValidacao) {
        validacaoTexto += ` • ${val.errorMessage}`;
      }
    }

    return validacaoTexto;
  }

  public concatUg(sigla, nome) {
    return sigla + ' - ' + nome;
  }

  public obterAuditoresCapacidadeTrabalho(): IPaaCoordenacaoAuditorModel[][] {
    return JSON.parse(localStorage.getItem('auditores')) as IPaaCoordenacaoAuditorModel[][]
  }

  private exibirMensagemErro(texto: string){
    Swal.fire({
      toast: true,
      position: 'top-end',
      icon: 'error',
      text: texto,
      showConfirmButton: false,
      timer: 2000,
      timerProgressBar: true,
    })
  }

  
}

