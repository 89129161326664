<div class="content">
  <div class="content-header">
    <div class="container-fluid">
      <div class="row mb-2">
        <div class="col-sm-12">
          <ol class="breadcrumb float-sm-left">
            <li class="breadcrumb-item">
              <a [routerLink]="['/']">
                <i class="fas fa-home"></i>
                Página Inicial
              </a>
            </li>
            <li class="breadcrumb-item">
              <a [routerLink]="['/tipo-universo']">Universo auditável</a>
            </li>
            <li class="breadcrumb-item active">{{ titulo }}</li>
          </ol>
        </div>
      </div>
    </div>
  </div>

  <div class="container-fluid">
    <div class="row">
      <div class="col-md-12">
        <div class="mat-elevation-z8">
          <div class="mat-elevation-z8">
            <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
              <!-- Coluna Subtipo -->
              <ng-container matColumnDef="subtipo">
                <th mat-header-cell *matHeaderCellDef> Subtipo </th>
                <td mat-cell *matCellDef="let element">
                  {{element.tipoUniversoAuditavelItemNome}}
                </td>
              </ng-container>

              <ng-container matColumnDef="macroprocesso">
                <th mat-header-cell *matHeaderCellDef> Macroprocesso </th>
                <td mat-cell *matCellDef="let element">
                  {{element.tipoUniversoAuditavelNome}}
                </td>
              </ng-container>

              <ng-container matColumnDef="processos">
                <th mat-header-cell *matHeaderCellDef> Processos </th>
                <td mat-cell *matCellDef="let element">
                  {{element.ordemExibicao }} .{{element.tipoUniversoAuditavelItemNome }}
                </td>
              </ng-container>

              <!-- Coluna Indicador -->
              <ng-container matColumnDef="indicador">
                <th mat-header-cell *matHeaderCellDef> Indicador </th>
                <td mat-cell *matCellDef="let element">
                  {{element.indicadorDescricao}}
                </td>
              </ng-container>

              <!-- Colunas Dinâmicas para Critérios -->
              <ng-container *ngFor="let criterio of criterios" [matColumnDef]="'criterio_' + criterio">
                <th mat-header-cell *matHeaderCellDef> {{criterio}} </th>
                <td mat-cell *matCellDef="let element">
                  {{getCriterioValue(element, criterio)}}
                </td>
              </ng-container>

              <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

