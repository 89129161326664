import { BaseFormComponent } from "../../../../shared/components/base-form/base-form.component";
import { Component, OnInit } from "@angular/core";
import { AuthService } from "../../../../services/auth.service";
import { QuestionarioUGService } from "../../../../services/questionarioug.service";
import { ActivatedRoute, Router } from "@angular/router";
import { MatDialog, MatDialogConfig } from "@angular/material/dialog";
import { UniversoAuditavelService } from "../../../../services/universo-auditavel.service";
import { SharedService } from "../../../../services/shared.service";
import { ToastrService } from "ngx-toastr";
import { BsLocaleService } from "ngx-bootstrap/datepicker";
import {
  ITipoUniversoItemModel,
  ITipoUniversoModel,
  ITipoUniversoUGModel
} from "../../../../models/tipo-universo.model";
import { TipoUniversoService } from "../../../../services/tipo-universo.service";
import { IUniversoAuditavelModel } from "../../../../models/universo-auditavel.model";
import {
  ModalTipoUniversoItemDescricaoComponent
} from "../../../tipo-universo-form/modal-tipo-universo-item/modal-tipo-universo-item-descricao/modal-tipo-universo-item-descricao.component";
import { moveItemInArray } from "@angular/cdk/drag-drop";
import Swal from "sweetalert2";
import { ModalCancelarComponent } from "../../../../shared/components/modal-cancelar/modal-cancelar-component";


@Component({
  selector: 'app-universo-auditavel-form',
  templateUrl: './universo-auditavel-form.component.html',
  styleUrls: ['./universo-auditavel-form.component.scss']
})

export class UniversoAuditavelFormComponent extends  BaseFormComponent  implements OnInit{


  public modelTipoUniverso : ITipoUniversoModel = {} as ITipoUniversoModel;
  public modelUniversoAuditavel : IUniversoAuditavelModel = {} as IUniversoAuditavelModel;
  public modelTipoUniversoItens : ITipoUniversoUGModel = {} as ITipoUniversoUGModel;
  public id: number;
  public unidadeGestora: number;
  public idTipoUniverso: number;
  public semDados: boolean;
  public titulo: string;
  public tipoDescription = "";
  public nomeUG: string[] = [];
  public idClassificacaoTipo: number;
  public alterou: boolean;


  constructor(
    private authService: AuthService,
    public matDialog: MatDialog,
    private universoAuditavelService: UniversoAuditavelService,
    private tipoUniversoService: TipoUniversoService,
    private sharedService: SharedService,
    route: ActivatedRoute,
    toastr: ToastrService,
    router: Router,
    localeService: BsLocaleService,
  ) {
    super(route, toastr, router, localeService, matDialog);
    const id = this.route.snapshot.paramMap.get("id");
    this.id = parseInt(id,10);
  }
  async ngOnInit() {

    if(this.id){
      const res = await this.universoAuditavelService.obterPorId(this.id);
      if(res.sucesso){
        this.modelUniversoAuditavel = res.dados;
        this.unidadeGestora = this.modelUniversoAuditavel.unidadeGestoraId;
        this.idTipoUniverso = this.modelUniversoAuditavel.tipoUniversoAuditavelId;

      }

      this.buscarTipoUniverso(this.idTipoUniverso);
    }
  }

  public async buscarTipoUniverso(idTipo:number){
    const res = await this.tipoUniversoService.obterPorId(idTipo);
    if(res.sucesso){
      this.modelTipoUniverso =  res.dados;

      this.titulo = 'Editar Mapeamento Universo Auditável ' + this.modelTipoUniverso.nome;
      this.tipoDescription = res.dados.tipoDescription;
      this.idClassificacaoTipo = res.dados.tipo;
    }
    this.buscarItens(this.idTipoUniverso, this.unidadeGestora);
  }
  public async buscarItens(idTipoUniverso: number, idUG: number){

    if(idTipoUniverso){

      const res = await this.tipoUniversoService.obterItens(idTipoUniverso, idUG);
      if(res.sucesso){
        this.modelTipoUniversoItens = res.dados;
        this.semDados = this.modelTipoUniversoItens.itens.length == 0;
        if(this.semDados){
          this.novoRegistro = true;
        }else{
          this.novoRegistro = false;
        }
        this.reordenar();
      }else{
        this.exibirMensagemErro(res.mensagem.descricao);
      }

    }
  }

  public exibirModalItemNovo(element: any, indx: string){


    let item = {} as ITipoUniversoItemModel;
    item.id = element?.id ?? 0;
    item.idItemPai = element?.idItemPai ?? 0;
    item.idTipoUniversoAuditavel = element?.idTipoUniversoAuditavel ?? this.modelTipoUniversoItens.idTipoUniversoAuditavel;
    item.idUG = element?.idUG ?? this.modelTipoUniversoItens.idUG;
    item.nome = element?.nome ?? "";
    item.tipo = element?.tipo ?? 1;

    let arrayIndices = JSON.parse("[" + indx + "]");

    if(arrayIndices.length == 1 && arrayIndices[0] == null){

      item.tipo = 1;
      item.tipoDescription = element?.tipoDescription ?? "SubTipo";

    }else if(arrayIndices.length == 1 && arrayIndices[0] != null){

      item.tipo = 2;
      item.tipoDescription = element?.tipoDescription ?? "Macroprocesso";

    }else if(arrayIndices.length == 2){

      item.tipo = 3;
      item.tipoDescription = element?.tipoDescription ?? "Processo";

    }


    const dialogConfig = new MatDialogConfig();
    dialogConfig.id = 'modal-descricao';
    dialogConfig.width = '450px';
    dialogConfig.hasBackdrop = true;
    dialogConfig.disableClose = true;
    dialogConfig.data = {
      item: item,
      indx: indx
    };

    const modal = this.matDialog.open(ModalTipoUniversoItemDescricaoComponent, dialogConfig);

    modal.afterClosed().subscribe((data) => {
      if(data){

        let novoItem = data.item as ITipoUniversoItemModel;

        //NÃO HAVENDO ÍNDICE, ADICIONA-SE NO ITEM RAIZ
        if(!data.indx){

          if(this.existeNomeMesmoNivel(this.modelTipoUniversoItens.itens, novoItem.nome)){
            this.exibirMensagemErro("Já existe SubTipo com este nome.");
            return;
          }

          novoItem.tipo  = 1;
          novoItem.tipoDescription="SubTipo";
          if(!this.modelTipoUniversoItens.itens){
            this.modelTipoUniversoItens.itens = [] as ITipoUniversoItemModel[];
          }
          this.modelTipoUniversoItens.itens.push(novoItem);
          this.alterou = true;
          this.reordenar();
          return;
        }

        //indx recebe os índices da hierarquia da árvore:
        //0 -> nível 1
        //0,0 -> nível 2
        //0,0,0 -> nível 3

        let arrayIndices = JSON.parse("[" + data.indx + "]");

        if(arrayIndices.length == 1){

          if(this.existeNomeMesmoNivel(this.modelTipoUniversoItens.itens[arrayIndices[0]].itens, novoItem.nome)){
            this.exibirMensagemErro("Já existe Macroprocesso com este nome.");
            return;
          }

          novoItem.tipo = 2;
          novoItem.tipoDescription = "Macroprocesso";

          if(!this.modelTipoUniversoItens.itens[arrayIndices[0]].itens){
            this.modelTipoUniversoItens.itens[arrayIndices[0]].itens = [] as ITipoUniversoItemModel[];
          }

          this.modelTipoUniversoItens.itens[arrayIndices[0]].itens.push(novoItem);

        }else if(arrayIndices.length == 2){

          if(this.existeNomeMesmoNivel(this.modelTipoUniversoItens.itens[arrayIndices[0]].itens[arrayIndices[1]].itens, novoItem.nome)){
            this.exibirMensagemErro("Já existe Macroprocesso com este nome.");
            return;
          }

          novoItem.tipo = 3;
          novoItem.tipoDescription = "Processo";

          if(!this.modelTipoUniversoItens.itens[arrayIndices[0]].itens[arrayIndices[1]].itens){
            this.modelTipoUniversoItens.itens[arrayIndices[0]].itens[arrayIndices[1]].itens = [] as ITipoUniversoItemModel[];
          }

          this.modelTipoUniversoItens.itens[arrayIndices[0]].itens[arrayIndices[1]].itens.push(novoItem);

        }
        this.alterou = true;
        this.reordenar();
      }
    })

  }
  public cancelar() {

    if(this.alterou){
      const dialogConfig = new MatDialogConfig();

      const modal = this.matDialog.open(ModalCancelarComponent, dialogConfig);
      modal.afterClosed().subscribe((resultado) => {
        if (resultado == true) {
          this.router.navigateByUrl(`/unidades-gestoras/${this.unidadeGestora}?aba=3`);

        }
      });
    }else{
      this.router.navigateByUrl(`/unidades-gestoras/${this.unidadeGestora}?aba=3`);

    }

  }

  public async concluir(salvarEFechar: boolean){
    this.submit = true;

    if(this.novoRegistro){

      if(!this.modelTipoUniversoItens.itens || this.modelTipoUniversoItens.itens.length == 0){
        this.exibirMensagemErro("É necessário incluir pelo menos 1 subtipo para salvar.")
        return;
      }

      if(this.idClassificacaoTipo == 2 && !this.modelTipoUniversoItens.idUG){
        return;
      }

      this.tipoUniversoService.inserirItens(this.modelTipoUniversoItens)
        .then((res) => {
          if(res.sucesso){
            this.exibirMensagemSucesso(res.mensagem.descricao);
            this.alterou = false;
            if(salvarEFechar){
              this.router.navigateByUrl(`/unidades-gestoras/${this.unidadeGestora}?aba=3`);
            }else{
              this.modelTipoUniversoItens = res.dados;
              this.novoRegistro = false;
            }
          }else{
            this.exibirMensagemErro(res.mensagem.descricao);
          }
        }).catch(err => this.exibirMensagemErro(err))

    }else{

      this.tipoUniversoService.atualizarItens(this.modelTipoUniversoItens)
        .then((res) => {
          if(res.sucesso){
            this.exibirMensagemSucesso(res.mensagem.descricao);
            this.alterou = false;
            if(salvarEFechar){
              this.router.navigateByUrl(`/unidades-gestoras/${this.unidadeGestora}?aba=3`);
            }

          }else{
            this.exibirMensagemErro(res.mensagem.descricao);
          }
        }).catch(err => this.exibirMensagemErro(err))

    }

  }
  private existeNomeMesmoNivel(itens: ITipoUniversoItemModel[], nome: string){
    return itens && itens.find(item => item.nome.toUpperCase() == nome.toUpperCase());
  }

  public reordenar(){
    this.modelTipoUniversoItens.itens.forEach((item, idx) => {
      item.ordem = (idx + 1);
      item.ordemExibicao = `1.${item.ordem.toString()}`;
      if(item.itens && item.itens.length > 0){
        this.fnReordenacao(item);
      }
    });
  }
  public fnReordenacao(item: ITipoUniversoItemModel){
    item.itens.forEach((subItem, subIdx) => {

      subItem.ordem = subIdx + 1;
      subItem.ordemExibicao = `${item.ordemExibicao}.${subItem.ordem.toString()}`
      if(subItem.itens && subItem.itens.length > 0){
        this.fnReordenacao(subItem);
      }
    });
  }

  public exibirModalItemEditar(element: ITipoUniversoItemModel, indx: string){

    const dialogConfig = new MatDialogConfig();
    dialogConfig.id = 'modal-descricao';
    dialogConfig.width = '450px';
    dialogConfig.hasBackdrop = true;
    dialogConfig.disableClose = true;
    dialogConfig.data = {
      item: element,
      indx: indx
    };

    const modal = this.matDialog.open(ModalTipoUniversoItemDescricaoComponent, dialogConfig);

    modal.afterClosed().subscribe((data) => {

      let novoItem = data.item as ITipoUniversoItemModel;
      let arrayIndices = JSON.parse("[" + data.indx + "]");

      if(arrayIndices.length == 1){

        this.modelTipoUniversoItens.itens[arrayIndices[0]].nome = novoItem.nome;

      }else if(arrayIndices.length == 2){

        this.modelTipoUniversoItens.itens[arrayIndices[0]].itens[arrayIndices[1]].nome = novoItem.nome;

      }else if(arrayIndices.length == 2){

        this.modelTipoUniversoItens.itens[arrayIndices[0]].itens[arrayIndices[1]].itens[arrayIndices[2]].nome = novoItem.nome;

      }

      this.alterou = true;

    })

  }

  public excluirItem(element: any, indx: string){

    Swal.fire({
      title: 'Excluir item',
      text: `Deseja realmente excluir este item?`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não',
    }).then(resConfirm => {
      if (resConfirm.isConfirmed) {

        let arrayIndices = JSON.parse("[" + indx + "]");

        if(arrayIndices.length == 1){
          this.modelTipoUniversoItens.itens.splice(arrayIndices[0], 1);
        }else if(arrayIndices.length == 2){
          this.modelTipoUniversoItens.itens[arrayIndices[0]].itens.splice(arrayIndices[1], 1);
        }else if (arrayIndices.length == 3){
          this.modelTipoUniversoItens.itens[arrayIndices[0]].itens[arrayIndices[1]].itens.splice(arrayIndices[2], 1);
        }

        this.alterou = true;
        this.reordenar();

      }else{
      }
    });


  }


  public moveUp(element: any, indx: string){
    let arrayIndices = JSON.parse("[" + indx + "]");

    if(arrayIndices.length == 1){

      if((arrayIndices[0]) == 0)
        return;

      moveItemInArray(this.modelTipoUniversoItens.itens, arrayIndices[0], arrayIndices[0]-1)

    }else if(arrayIndices.length == 2){

      if((arrayIndices[1]) == 0)
        return;

      moveItemInArray(this.modelTipoUniversoItens.itens[arrayIndices[0]].itens, arrayIndices[1], arrayIndices[1]-1)

    }else{
      if((arrayIndices[2]) == 0)
        return;

      moveItemInArray(this.modelTipoUniversoItens.itens[arrayIndices[0]].itens[arrayIndices[1]].itens, arrayIndices[2], arrayIndices[2]-1)
    }

    this.alterou = true;
    this.reordenar();
  }

  public moveDown(element: any, indx: string){

    let arrayIndices = JSON.parse("[" + indx + "]");

    if(arrayIndices.length == 1){

      if((arrayIndices[0]) == this.modelTipoUniversoItens.itens.length -1)
        return;

      moveItemInArray(this.modelTipoUniversoItens.itens, arrayIndices[0], arrayIndices[0]+1)

    }else if(arrayIndices.length == 2){

      if((arrayIndices[1]) == this.modelTipoUniversoItens.itens[arrayIndices[0]].itens.length -1)
        return;

      moveItemInArray(this.modelTipoUniversoItens.itens[arrayIndices[0]].itens, arrayIndices[1], arrayIndices[1]+1)

    }else{
      if((arrayIndices[2]) == this.modelTipoUniversoItens.itens[arrayIndices[0]].itens[arrayIndices[1]].itens.length -1)
        return;

      moveItemInArray(this.modelTipoUniversoItens.itens[arrayIndices[0]].itens[arrayIndices[1]].itens, arrayIndices[2], arrayIndices[2]+1)
    }

    this.alterou = true;
    this.reordenar();

  }
}
