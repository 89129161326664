import { AfterViewInit, Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';
import { ToastrService } from 'ngx-toastr';
import { IEscalas } from 'src/app/models/escalas.model';
import { EscalasService } from 'src/app/services/escalas.service';
import { BaseFormComponent } from 'src/app/shared/components/base-form/base-form.component';
import { FormMagnitudeEscalaComponent } from './form-magnitude-escala/form-magnitude-escala.component';
import { GridMagnitudeEscalaComponent } from './grid-magnitude-escala/grid-magnitude-escala.component';

@Component({
  selector: 'app-modal-escalas',
  templateUrl: './modal-escalas.component.html',
  styleUrls: ['./modal-escalas.component.scss']
})
export class ModalEscalasComponent extends BaseFormComponent implements AfterViewInit, OnInit {

  @ViewChild(FormMagnitudeEscalaComponent, { static: false }) magnitudeEscalas!: FormMagnitudeEscalaComponent;
  @ViewChild(GridMagnitudeEscalaComponent, { static: false }) gridMagnitudeEscalas!: GridMagnitudeEscalaComponent;

  model = {} as IEscalas;
  anoSelecionado: number;
  txtBtnConcluir: string;
  campoObrigatorio = false;
  atualizando = false;

  titulo = 'Escalas'

  constructor(
    route: ActivatedRoute,
    private dialogRef: MatDialogRef<ModalEscalasComponent>,
    public toastr: ToastrService,
    localeService: BsLocaleService,
    router: Router,
    private serviceEscalas: EscalasService,
    public matDialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data
  ) {
    super(route, toastr, router, localeService, matDialog);

    this.model.id = data?.id;
    this.model.nome = data?.nome;
    this.model.criteriosAvaliacaoRiscosId = data?.criteriosAvaliacaoRiscosId;
    this.model.ativo = data?.ativo;
    this.visualizar = data?.visualizar

    if (!this.model.id || this.model.id == 0) {
      this.atualizando = false;
      this.txtBtnConcluir = "Inserir"
      this.titulo = 'Adicionar Escala'
    }
    else {
      this.atualizando = true;
      this.txtBtnConcluir = "Atualizar"
      this.titulo = 'Adicionar Magnitudes da Escala de Risco'
    }
  }

  ngAfterViewInit(): void {
    this.gridMagnitudeEscalas.setEscalaId(this.model.id)
    this.magnitudeEscalas.setEscalaId(this.model.id)
  }

  updateData() {
    this.gridMagnitudeEscalas.setEscalaId(this.model.id);
  }

  setListData(e : any){
    this.magnitudeEscalas.setListData(e)
  }

  ngOnInit(): void {
  }

  cancelar() {
    this.dialogRef.close(false);
  }

  concluir() {
    if (this.atualizando) {
      this.serviceEscalas.atualizar(this.model).then((res) => { this.dialogRef.close(true); })
    } else {
      this.serviceEscalas.inserir(this.model).then((res) => { this.dialogRef.close(true); })
    }
  }

}
