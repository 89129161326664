import { LocationStrategy } from '@angular/common';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IBaseModel } from '../models/base.model';
import { ITipoUniversoModel, ITipoUniversoUGModel } from '../models/tipo-universo.model';
import { PaginacaoModel } from '../models/paginacao.model';
import { FiltroModel } from '../models/filtro.model';
import { BaseService } from './base.service';
import { filterBy } from '../core/helpers/filterby';
import { IFiltroTipoUniverso } from '../models/filtros/filtro-tipo-universo.model';

@Injectable({
  providedIn: 'root',
})

export class TipoUniversoService extends BaseService{
    constructor(httpClient: HttpClient, locationStrategy: LocationStrategy) {
        super(httpClient, locationStrategy);
      }

      public obter(filtro: IFiltroTipoUniverso): Promise<IBaseModel<PaginacaoModel<ITipoUniversoModel[]>>> {
        return this.httpClient
          .get<IBaseModel<PaginacaoModel<ITipoUniversoModel[]>>>(filterBy(filtro,`${this.apiBaseUrl}/tipoUniversoAuditavel`))
          .toPromise();
      }

      public obterPorId(id: number): Promise<IBaseModel<ITipoUniversoModel>> {
        return this.httpClient
          .get<IBaseModel<ITipoUniversoModel>>(`${this.apiBaseUrl}/tipoUniversoAuditavel/${id}`)
          .toPromise();
      }

      public obterItens(id:number, idUg: number): Promise<IBaseModel<ITipoUniversoUGModel>>{
        return this.httpClient
            .get<IBaseModel<ITipoUniversoUGModel>>(`${this.apiBaseUrl}/tipoUniversoAuditavel/${id}/${idUg}`)
            .toPromise();
      }

      public async reativar(id: number): Promise<IBaseModel<void>> {
        return this.httpClient
          .post<IBaseModel<void>>(`${this.apiBaseUrl}/tipoUniversoAuditavel/${id}/reativar`, {})
          .toPromise();
      }
    
      public async inativar(id: number): Promise<IBaseModel<void>> {
        return this.httpClient
          .post<IBaseModel<void>>(`${this.apiBaseUrl}/tipoUniversoAuditavel/${id}/inativar`, {})
          .toPromise();
      }

      public async excluir(id: number): Promise<IBaseModel<void>> {
        return this.httpClient
          .delete<IBaseModel<void>>(`${this.apiBaseUrl}/tipoUniversoAuditavel/${id}`, {})
          .toPromise();
      }

      public async inserir(data: ITipoUniversoModel): Promise<IBaseModel<ITipoUniversoModel>>{
        return this.httpClient
              .post<IBaseModel<ITipoUniversoModel>>(`${this.apiBaseUrl}/tipoUniversoAuditavel`, data)
              .toPromise();
      }

      public async atualizar(data: ITipoUniversoModel): Promise<IBaseModel<ITipoUniversoModel>>{
        return this.httpClient
              .put<IBaseModel<ITipoUniversoModel>>(`${this.apiBaseUrl}/tipoUniversoAuditavel`, data)
              .toPromise();
      }

      public async inserirItens(data: ITipoUniversoUGModel): Promise<IBaseModel<ITipoUniversoUGModel>>{
        return this.httpClient
              .post<IBaseModel<ITipoUniversoUGModel>>(`${this.apiBaseUrl}/tipoUniversoAuditavel/inserirItens`, data)
              .toPromise();
      }

      public async atualizarItens(data: ITipoUniversoUGModel): Promise<IBaseModel<ITipoUniversoUGModel>>{
        return this.httpClient
              .put<IBaseModel<ITipoUniversoUGModel>>(`${this.apiBaseUrl}/tipoUniversoAuditavel/atualizarItens`, data)
              .toPromise();
      }

      public async excluirUG(id: number, idUG: number): Promise<IBaseModel<void>> {
        return this.httpClient
          .delete<IBaseModel<void>>(`${this.apiBaseUrl}/tipoUniversoAuditavel/${id}/excluirUG/${idUG}`, {})
          .toPromise();
      }
}