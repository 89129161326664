<div class="content">
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-12">
        <div class="card card-outline card-lightblue">
          <div class="card-header">
            <h3 class="card-title mb-0">
              <i class="fas fa-table pr-2"></i>
              Universo Auditável
            </h3>
          </div>

          <div class="card-body p-2">
            <div class="mat-elevation-z4">
              <mat-toolbar class="table-header">
                <mat-toolbar-row>
                  <button type="button" class="btn btn-success btn-sm mr-1" (click)="novo()" [hidden]="!permissoes.incluir" [disabled]="botaoNovoDisable">
                    <i class="far fa-file"></i> Novo
                  </button>
                  <button type="button" class="btn btn-primary btn-sm mr-1" [hidden]="!permissoes.editar"  [disabled]="!selectedModel || !selectedModel.ativo"
                          (click)="editar(selectedModel.id)">
                    <i class="far fas fa-pen"></i> Editar
                  </button>
                  <button type="button" class="btn btn-info btn-sm mr-1" [disabled]="!selectedModel || botaoVisualizarDisable" [hidden]="!permissoes.visualizar"
                          (click)="visualizar(selectedModel.id)">
                    <i class="far fas fa-eye"></i> Visualizar
                  </button>
                  <button type="button" class="btn btn-danger btn-sm mr-1" [disabled]="isButtonDisabled() || botaoExcluirDisable"  mwlConfirmationPopover popoverTitle="Excluir"
                          [hidden]="!permissoes.excluir" popoverMessage="Deseja realmente excluir o questionário?" placement="bottom" confirmText="Sim"
                          confirmButtonType="primary" cancelText="Não" (confirm)="excluir(selectedModel.id)">
                    <i class="fa fa-trash"></i> Excluir
                  </button>
                  <button type="button" class="btn btn-info btn-sm mr-1" [disabled]="!selectedModel || botaoImprimirDisable" [hidden]="!permissoes.imprimir"
                          (click)="imprimir(selectedModel.id)">
                    <i class="fas fa-print"></i> Imprimir
                  </button>

                  <div class="dropdown custom-dropdown" [hidden]="!permissoes.editar">
                    <button class="btn btn-info btn-sm dropdown-toggle" type="button" [disabled]="isSelect() || !selectedModel" id="dropdownMenuButton" data-toggle="dropdown"
                            aria-haspopup="true" aria-expanded="false">
                      Preenchimento
                    </button>
                    <div class="dropdown-menu"  aria-labelledby="dropdownMenuButton">
                      <a class="dropdown-item"
                         (click)="preencher(selectedModel.id)">
                        <i class="fas fa-pen-square"></i> Preencher Formulário
                      </a>
                      <a class="dropdown-item" (click)="solicitar(selectedModel.id)">
                        <i class="fas fa-paper-plane"></i> Solicitar
                      </a>
                      <a class="dropdown-item" (click)="avocar(selectedModel.id)">
                        <i class="fas fa-undo-alt"></i> Avocar
                      </a>
                      <a class="dropdown-item" (click)="avaliar(selectedModel.id)">
                        <i class="fas fa-pen"></i> Revisar Formulário
                      </a>
                    </div>
                  </div>

                  <button type="button" class="btn btn-warning btn-sm mr-1"  [hidden]="!permissoes.excluir"
                          (click)="importarArquivo()" [disabled]="botaoImportarDisable">
                    <i class="far fas fa-save"></i> Importar
                  </button>

                  <span class="fill-remaining-space"></span>
                  <div class="input-group input-group-sm" style="width: 200px;">
                    <input type="number" name="table_search" class="form-control float-right" (keyup.enter)="buscar()" placeholder="Pesquisar nº versão"
                           [(ngModel)]="filtro.versao" />
                    <div class="input-group-append">
                      <button type="button" class="btn btn-info" (click)="buscar()">
                        <i class="fas fa-search"></i>
                      </button>
                    </div>
                  </div>
                  <div class="btn-group">
                    <button type="button" data-toggle="dropdown" class="btn btn-tool btn-default dropdown-toggle">
                      Tipo
                      <i class="fas fa-filter"></i>
                    </button>
                    <div role="menu" class="dropdown-menu dropdown-menu-right dropdown-menu-checkbox">
                      <a
                        *ngFor="let option of optionsTipoUniverso"
                        (click)="filtro.tipoUniversoAuditavelId = option.id; buscar()"
                        class="dropdown-item">
                        <i [ngClass]="filtro?.tipoUniversoAuditavelId === option.id ? 'fas fa-check-circle' : 'far fa-circle'"></i>
                        {{ option.nome }}
                      </a>
                    </div>
                  </div>

                  <div class="btn-group">
                    <button type="button" data-toggle="dropdown" class="btn btn-tool btn-default dropdown-toggle">
                      Situação
                      <i class="fas fa-filter"></i>
                    </button>
                    <div role="menu" class="dropdown-menu dropdown-menu-right dropdown-menu-checkbox">
                      <a
                        *ngFor="let option of optionsSituacao"
                        (click)="filtro.status = option.nome; buscar()"
                        class="dropdown-item">
                        <i [ngClass]="filtro?.status === option.nome ? 'fas fa-check-circle' : 'far fa-circle'"></i>
                        {{ option.nome }}
                      </a>
                    </div>
                  </div>
                  <div class="btn-group">
                    <button type="button" data-toggle="dropdown" class="btn btn-tool btn-default dropdown-toggle">
                      <i class="fas fa-filter"></i>
                    </button>
                    <div role="menu" class="dropdown-menu dropdown-menu-right dropdown-menu-checkbox">
                      <a (click)="filtro.ativo = undefined; buscar()" class="dropdown-item"><i [ngClass]="filtro.ativo === undefined ? 'fas fa-check-circle' : 'far fa-circle'"></i>
                        Exibir todos</a>
                      <a (click)="filtro.ativo = true; buscar()" class="dropdown-item"><i [ngClass]="filtro.ativo === true ? 'fas fa-check-circle' : 'far fa-circle'"></i>
                        Exibir somente ativos</a>
                      <a (click)="filtro.ativo = false; buscar()" class="dropdown-item"><i [ngClass]="filtro.ativo === false ? 'fas fa-check-circle' : 'far fa-circle'"></i>
                        Exibir somente inativos</a>
                    </div>
                  </div>

                </mat-toolbar-row>
              </mat-toolbar>

              <table mat-table [dataSource]="dataSource" class="w-100" matSort matSortDisableClear matSortActive="id"
                     matSortDirection="asc" class="mat-elevation-z8" [hidden]="semDados" (matSortChange)="sortData($event)">
                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns:
                                      displayedColumns; " (click)="selecionar(row)" class="clickable"
                    [ngClass]="{'bg-light': row.selecionado}">
                </tr>
                <ng-container matColumnDef="selecao">
                  <th mat-header-cell *matHeaderCellDef> </th>
                  <td mat-cell *matCellDef="let element">
                    <mat-checkbox (click)="$event.stopPropagation()" color="primary"
                                  (change)="selectedModel = ($event.checked ? element : null)"
                                  [checked]="selectedModel?.id === element.id">
                    </mat-checkbox>
                  </td>
                </ng-container>

                <ng-container matColumnDef="versao">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header> Versão </th>
                  <td mat-cell *matCellDef="let element"> {{element.versao}} </td>
                </ng-container>

                <ng-container matColumnDef="dataCriacaoFormatada">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header> Data </th>
                  <td mat-cell *matCellDef="let element"> {{element.dataCriacaoFormatada}} </td>
                </ng-container>

                <ng-container matColumnDef="nomeTipoUniversoAuditavel">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header> Tipo Universo Auditável </th>
                  <td mat-cell *matCellDef="let element"> {{element.nomeTipoUniversoAuditavel}} </td>
                </ng-container>

                <ng-container matColumnDef="status">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header> Situação </th>
                  <td mat-cell *matCellDef="let element"> {{element.status}} </td>
                </ng-container>

                <ng-container matColumnDef="ativo">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header> Status </th>
                  <td mat-cell *matCellDef="let element">
                    <span *ngIf="element.ativo" class="badge bg-success text-xs">Ativo</span>
                    <span *ngIf="!element.ativo" class="badge bg-gray-dark text-xs">Inativo</span>
                  </td>
                </ng-container>

              </table>
              <h5 [hidden]="!semDados" class="table-no-data">Nenhum registro encontrado.</h5>
              <mat-paginator #TableOnePaginator="matPaginator" class="mt-4"
                             [pageSizeOptions]="[15, 30, 50]" showFirstLastButtons [length]="totalItens"
                             (page)="selectedModel = null; pageEvent = pageChanged($event)">
              </mat-paginator>

            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
