<div id="modal-content-wrapper" role="dialog" tabindex="-1">
  <div
    class="modal-dialog modal-dialog-scrollable modal-xl" role="document">
    <div class="modal-content">
      <h5 class="modal-title">{{titulo}}</h5>
      <div class="modal-body">
        
        <div>
          
          <div class="row">
            <div class="form-group col-md-6">
              <label
                    for="selectClassificacao"
                    class="mb-1"
                    >Classificação do Tipo</label>
              <input
              type="text"
              name="tipoTxt"
              class="form-control float"
              disabled
              [ngModel]="tipoDescription"
              [ngModelOptions]="{ standalone: true }"
            />
            </div>
    
            <div class="form-group col-md-6" *ngIf="idClassificacaoTipo == 2">
              <label
                    for="selectClassificacao"
                    class="mb-1"
                    >Unidade Gestora</label>
                    <div
                      class="input-group input-group-sm"
                      style="width: 100%;">
                      <input
                        type="text"
                        name="tipoTxt"
                        class="form-control float-right"
                        disabled
                        [ngModel]="nomeUG"
                        [ngModelOptions]="{ standalone: true }"
                        [ngClass]="{ 'is-invalid': submit && !model.idUG }"
                      />
                      <div class="input-group-append">
                        <button
                          type="button"
                          [hidden]="visualizar"
                          class="btn btn-info"
                          (click)="exibirModalUnidadeGestora()">
                          <i class="fas fa-search"></i>
                        </button>
                      </div>
                      <div *ngIf="submit && !model.idUG" class="invalid-feedback">
                        <div>Campo obrigatório</div>
                      </div>
                    </div>
            </div>
          </div>
    
          <div class="row">
            <div class="card card-outline card-lightblue col-md-12">
              
              <div class="card-body p2">
                <div class="mat-elevation-z4">
                  <mat-toolbar class="table-header">
                    <mat-toolbar-row>
                      <button 
                      class="btn btn-primary btn-sm mr" 
                      (click)="exibirModalItemNovo(null, null)"
                      [hidden]="visualizar"
                      >
                        <i></i>Adicionar Subtipo
                      </button>
                    </mat-toolbar-row>
                  </mat-toolbar>
                    <table class="table table-condensed">
                      <thead>
                          <tr>
                              <th class="col-md-2">#</th>
                              <th class="col-md-6">Nome</th>
                              <th class="col-md-2"></th>
                              <th class="col-md-2">Tipo</th>
                              <th class="col-md-3 text-center">Ações</th>
                          </tr>
                      </thead>
    
                      
                      <ng-container *ngTemplateOutlet="recursiveTable; context:{ list: model.itens, indx: null }"></ng-container>                          
    
                  </table>

                
                  <ng-template #recursiveTable let-list="list" let-indx="indx">
    
                      <ng-container *ngFor="let subTipo of list; let i = index">
                        
                        <tbody>
                          <tr [attr.disabled]="visualizar"
                          *ngIf="{ indice: indx ? indx + ',' + i.toString() : i.toString() } as variable">
                              <th [style]="subTipo.tipo === 1 ? '' : (subTipo.tipo === 2 ? 'padding-left:25px' : 'padding-left:40px')">
                                  <div class="drag-handle" >
                                      
                                      {{ subTipo?.ordemExibicao }}
                                  </div>
                              </th>
                              <td >
                                <span class="mat-column" [ngClass]="{ bold: subTipo.tipo === 1 }">{{ subTipo?.nome }} </span>
                              </td>
                              <td class="text-center">
                                <div class="btn-group mr-1">
                                  <button
                                    [hidden]="visualizar"
                                    [disabled]="i == 0"
                                    class="btn btn-primary btn-xs text-left"
                                    (click)="moveUp(subTipo, variable.indice)">
                                    <i class="fas fa-arrow-up"></i>
                                  </button>  
                                  <button
                                    [hidden]="visualizar"
                                    [disabled]="i == list.length - 1"
                                    class="btn btn-primary btn-xs text-left"
                                    (click)="moveDown(subTipo, variable.indice)">
                                    <i class="fas fa-arrow-down"></i>
                                  </button>  
                                  <span></span>
                                </div>
                              </td>
                              <td>
                                <span class="mat-column" [ngClass]="{ bold: subTipo.tipo === 1 }">{{ subTipo?.tipoDescription }}</span>
                              </td>
    
                              <td class="text-center">
                                  <div class="btn-group mr-1">
                                    <button
                                      [hidden]="visualizar"
                                      class="btn btn-primary btn-xs text-left"
                                      (click)="exibirModalItemEditar(subTipo, variable.indice)"
                                      >
                                      <i class="fas fa-pen"></i>
                                    </button>  
                                    <button
                                      [hidden]="visualizar || subTipo.tipo == 3"
                                      class="btn btn-success btn-xs text-left"
                                      (click)="exibirModalItemNovo(null, variable.indice)"
                                      [matTooltip]="subTipo.tipo == 1 ? 'Macroprocesso' : 'Processo'"
                                      >
                                      <i class="fas fa-plus"></i>
                                      </button>
                                      <button
                                      [hidden]="visualizar"
                                      type="button"
                                      class="btn btn-danger btn-xs text-left"
                                      (click)="excluirItem(subTipo, variable.indice)"
                                      >
                                      <i class="far fa-trash-alt"></i>
                                    </button>
                                  </div>
                              </td>
                          </tr>    
                        </tbody>
    
                        <ng-container *ngIf="subTipo?.itens?.length > 0">
                          <ng-container *ngTemplateOutlet="recursiveTable; context:{ list: subTipo.itens, indx: indx ? indx + ',' + i.toString() : i.toString() }"></ng-container>
                        </ng-container>
                        
                    </ng-container>
                  </ng-template>
    
                  <h5
                      [hidden]="!semDados"
                      class="table-no-data"
                  >
                      Nenhum item cadastrado
                  </h5>
                </div>
              </div>
    
            </div>
          </div>
        </div>
        
      </div>
      <div class="modal-footer">

        <div class="btn-group" 
        [hidden]="visualizar">
          <button class="btn btn-primary btn-sm mr" 
          (click)="concluir(false)">
              <i class="far fa-save"></i> Salvar
          </button>
            <button type="button" class="btn btn-primary btn-sm mr dropdown-toggle dropdown-icon" data-toggle="dropdown" aria-expanded="false"></button>
            <div class="dropdown-menu" role="menu">
              <a class="dropdown-item" (click)="concluir(false)">Salvar</a>
              <a class="dropdown-item" (click)="concluir(true)">Salvar e fechar</a>
            </div>
        </div>
  
        <button type="button" class="btn btn-default btn-sm" (click)="cancelar()">
        <i class="fas fa-times"></i> Cancelar
        </button>
      </div>
    </div>
  </div>
</div>