import { BaseListComponent } from "../../../../shared/components/base-list/base-list.component";
import { Component } from "@angular/core";
import { FiltroModel } from "../../../../models/filtro.model";
import { AuthService } from "../../../../services/auth.service";
import { EntendendoEntidadeService } from "../../../../services/entendendo-entidade.service";
import { MatDialog, MatDialogConfig, MatDialogRef } from "@angular/material/dialog";
import { Router } from "@angular/router";
import { SharedService } from "../../../../services/shared.service";
import { TipoUniversoService } from "../../../../services/tipo-universo.service";
import {
  ModalSelecaoTipoUniversoComponent
} from "../modal-selecao-tipo-universo/modal-selecao-tipo-universo.component";
import { UniversoAuditavelService } from "../../../../services/universo-auditavel.service";
import { IUniversoAuditavelModel } from "../../../../models/universo-auditavel.model";
import { MatTableDataSource } from "@angular/material/table";
import { FiltroUniversoAuditavel } from "../../../../models/filtro-universo-auditavel";
import {
  ModalUniversoAuditavelNovaVersaoComponent
} from "../../shared/modal-universo-auditavel-nova-versao/modal-universo-auditavel-nova-versao.component";
import { TipoUniversoEnum } from "../../../../models/enum/tipo-universo.enum";


@Component({
	selector: 'app-gerar-universo-auditavel',
	templateUrl: './gerar-universo-auditavel.component.html',
	styleUrls: ['./gerar-universo-auditavel.component.scss']
})
export class GerarUniversoAuditavelComponent extends  BaseListComponent{
	public filtro = {} as FiltroUniversoAuditavel;
	unidadeGestoraId: number;
	tipoUniversoId: number;
	tipoUniverso: number;
  public dataSource: any[] = [];
  private currentDialogRef: MatDialogRef<any>;
	constructor(
		private authService: AuthService,
		private universoAuditavelService: UniversoAuditavelService,
		private tipoUniversoService: TipoUniversoService,
		private matDialog: MatDialog,
		private router: Router,
		private sharedService: SharedService
	) {
		super();
	}

	ngOnInit(): void {
    this.filtro.unidadeGestoraId = this.sharedService.getId();
    this.unidadeGestoraId = this.sharedService.getId();
    console.log(this.filtro.unidadeGestoraId);
    console.log(this.unidadeGestoraId);
    this.buscar();
	}

  public buscar() {
    this.universoAuditavelService.obter(this.filtro).then((res)=>{
      this.dataSource = res.dados.resultado;
    }).catch((err)=> {
      this.exibirMensagemErro(err.mensagem);
    });
  }

	public buscarTipoUniversoAuditavel(){

		const dialogConfig = new MatDialogConfig();
		dialogConfig.id = 'modal-component';
		dialogConfig.width = '650px';
		dialogConfig.hasBackdrop = true;
		dialogConfig.disableClose = true;

    const modal = this.matDialog.open(ModalSelecaoTipoUniversoComponent , dialogConfig );

    modal.afterClosed().subscribe((data) => {
      if(data){
        this.filtro.pesquisa = data.nome;
        this.tipoUniversoId = data.id;
        this.tipoUniverso = data.tipo;
      }
    })
	}

  public async  verificaCriacao(){
    const itemExiste = this.dataSource.filter(item => item.nomeTipoUniversoAuditavel === this.filtro.pesquisa);
    if(this.tipoUniverso === TipoUniversoEnum.NaoPadronizado){

      const retorno =   await  this.tipoUniversoService.obterPorId(this.tipoUniversoId);
      if(retorno.sucesso){
        const verificaUG =  retorno.dados.ugs.filter(res => res.idUG === this.unidadeGestoraId);
        if(verificaUG.length === 0){
          this.exibirMensagemAlerta("Unidade Gestora não cadastrada no Tipo de Universo Não-Padronizado selecionado!");
          return ;
        }
      }
    }

    if(itemExiste.length > 0){
      const dialogConfig = new MatDialogConfig();
      dialogConfig.id = 'modal-component';
      dialogConfig.width = '650px';
      dialogConfig.hasBackdrop = true;
      dialogConfig.disableClose = true;
      dialogConfig.data = {
        source: 'novo',
        unidadeGestoraId: this.unidadeGestoraId,
        tipoUniversoId: this.tipoUniversoId,
        tipoUniverso: this.tipoUniverso

      };
      this.currentDialogRef = this.matDialog.open(ModalUniversoAuditavelNovaVersaoComponent, dialogConfig);
      this.currentDialogRef.afterClosed().subscribe((data) => {
        this.buscar();
      });
    }else{
      this.gerarUniversoAuditavel();
    }

  }


  public async verificaTipoNaoPadronizado(){
    const item = await  this.tipoUniversoService.obterPorId(this.tipoUniversoId);

    if(item.sucesso){
      const verificaUG =  item.dados.ugs.filter(res => res.idUG === this.unidadeGestoraId);

      if(verificaUG.length > 1){
        return true;
      }else{
        this.exibirMensagemAlerta("Unidade Gestora não cadastrada no Tipo de Universo Não-Padronizado selecionado!");
        return ;
      }
    }
  }


  public gerarUniversoAuditavel(){

    if(!this.tipoUniversoId || this.tipoUniversoId == 0){
        this.exibirMensagemErro("Informe o modelo um tipo de Universo Auditável")
        return;
      }

      let params: IUniversoAuditavelModel = {
        unidadeGestoraId: this.unidadeGestoraId,
        tipoUniversoAuditavelId: this.tipoUniversoId
      };

      this.universoAuditavelService
        .inserir(params)
        .then((res) => {
          if (res.sucesso) {
            this.router.navigateByUrl(`/unidades-gestoras/${this.unidadeGestoraId}?aba=3`);

          } else {
            this.exibirMensagemAlerta(res.mensagem.descricao);
          }
        })
        .catch((err) => {
          this.exibirMensagemErro(err.mensagem);
        })
        .finally(() => {
          // this.buscar();
        });
    }

}
