import { Component, OnInit } from "@angular/core";
import { BaseFormComponent } from "../../../../shared/components/base-form/base-form.component";
import { ToastrService } from "ngx-toastr";
import { ActivatedRoute, Router } from "@angular/router";
import { AuthService } from "../../../../services/auth.service";
import { BsLocaleService } from "ngx-bootstrap/datepicker";
import { PerguntaService } from "../../../../services/pergunta.service";
import { TabelaDominioService } from "../../../../services/tabela-dominio.service";
import { MatDialog } from "@angular/material/dialog";
import { MatGridListModule } from "@angular/material/grid-list";
import { UniversoAuditavelService } from "../../../../services/universo-auditavel.service";
import {
  IUniversoAuditavelItemRespostaItensModel,
  IUniversoAuditavelModel
} from "../../../../models/universo-auditavel.model";
import { TipoUniversoEnum } from "../../../../models/enum/tipo-universo.enum";
import { TipoUniversoService } from "../../../../services/tipo-universo.service";
import { UnidadeGestoraService } from "../../../../services/unidade-gestora.service";
import {
  ITipoUniversoItemModel,
  ITipoUniversoModel,
  ITipoUniversoUGModel
} from "../../../../models/tipo-universo.model";
import { UniversoAuditavelItemRespostaService } from "../../../../services/universo-auditavel-item-resposta.service";
import { MatTableDataSource } from "@angular/material/table";

@Component({
  selector: 'app-visualizar-universo-auditavel',
  templateUrl: './visualizar-universo-auditavel.component.html',
  styleUrls: ['./visualizar-universo-auditavel.component.scss']
})

export class VisualizarUniversoAuditavelComponent extends BaseFormComponent implements OnInit{
  constructor(
    toastr: ToastrService,
    route: ActivatedRoute,
    router: Router,
    private authService: AuthService,
    localeService: BsLocaleService,
    private perguntaService: PerguntaService,
    private tabelaDominioService: TabelaDominioService,
    private universoAuditavelService: UniversoAuditavelService,
    private universoAuditavelItemService: UniversoAuditavelItemRespostaService,
    public matDialog: MatDialog,
    private tipoUniversoService: TipoUniversoService,
    private unidadeGestoraService: UnidadeGestoraService,
    public matGridListModule: MatGridListModule
  ) {
    super(route, toastr, router, localeService, matDialog);
    this.dataSource = new MatTableDataSource<IUniversoAuditavelItemRespostaItensModel>();

  }
  public modelUniversoAuditavel = {} as IUniversoAuditavelModel;
  public dropdownData: { value: string, text: string , unidade: string}[] = [];
  public semDados: boolean;
  public modelTipoUniverso: ITipoUniversoModel = {} as ITipoUniversoModel;
  public modelTipoUniversoItens: IUniversoAuditavelItemRespostaItensModel = {} as IUniversoAuditavelItemRespostaItensModel;
  unidadeGestoraNome: string = '';
  unidadeGestora: number;

   data = [
    {
      "universoAuditavelId": 3,
      "tipoUniversoAuditavelId": 1,
      "tipoUniversoAuditavelNome": "Ênfases",
      "indicadorId": 62,
      "indicadorDescricao": "Indicador para anexar os critérios utilizados para Mapeamento do Universo Auditável",
      "tipoUniversoAuditavelItemId": 149,
      "tipoUniversoAuditavelItemNome": "Bens e serviços de Tecnologia da Informação EDIÇÃO",
      "ordem": 1,
      "tipo": 1,
      "idItemPai": 0,
      "universoAuditavelItemRespostas": "",
      "itens": [
        {
          "universoAuditavelId": 3,
          "tipoUniversoAuditavelId": 1,
          "tipoUniversoAuditavelNome": "Ênfases",
          "indicadorId": 62,
          "indicadorDescricao": "Indicador para anexar os critérios utilizados para Mapeamento do Universo Auditável",
          "tipoUniversoAuditavelItemId": 150,
          "tipoUniversoAuditavelItemNome": "Aquisição de Bens e Serviços de TI EDIÇÃO",
          "ordem": 1,
          "tipo": 2,
          "idItemPai": 149,
          "universoAuditavelItemRespostas": "",
          "itens": [
            {
              "universoAuditavelId": 3,
              "tipoUniversoAuditavelId": 1,
              "tipoUniversoAuditavelNome": "Ênfases",
              "indicadorId": 62,
              "indicadorDescricao": "Indicador para anexar os critérios utilizados para Mapeamento do Universo Auditável",
              "criterioId": 130,
              "criterioNome": "Ações orçamentárias envolvidas",
              "tipoUniversoAuditavelItemId": 151,
              "tipoUniversoAuditavelItemNome": "Elaboração de ETP e TR de Bens e Serviços de TI",
              "ordem": 1,
              "tipo": 3,
              "idItemPai": 150,
              "universoAuditavelItemRespostas": "1",
              "itens": [],
              "ordemExibicao": "1.1.1"
            },
            {
              "universoAuditavelId": 3,
              "tipoUniversoAuditavelId": 1,
              "tipoUniversoAuditavelNome": "Ênfases",
              "indicadorId": 62,
              "indicadorDescricao": "Indicador para anexar os critérios utilizados para Mapeamento do Universo Auditável",
              "criterioId": 130,
              "criterioNome": "Ações orçamentárias envolvidas",
              "tipoUniversoAuditavelItemId": 152,
              "tipoUniversoAuditavelItemNome": "Elaboração de Minuta de Contrato EDIÇÃO",
              "ordem": 2,
              "tipo": 3,
              "idItemPai": 150,
              "universoAuditavelItemRespostas": "2",
              "itens": [],
              "ordemExibicao": "1.1.2"
            }
          ],
          "ordemExibicao": "1.1"
        },
        {
          "universoAuditavelId": 3,
          "tipoUniversoAuditavelId": 1,
          "tipoUniversoAuditavelNome": "Ênfases",
          "indicadorId": 62,
          "indicadorDescricao": "Indicador para anexar os critérios utilizados para Mapeamento do Universo Auditável",
          "tipoUniversoAuditavelItemId": 153,
          "tipoUniversoAuditavelItemNome": "Manutenção de Bens e Serviços de TI",
          "ordem": 2,
          "tipo": 2,
          "idItemPai": 149,
          "universoAuditavelItemRespostas": "",
          "itens": [
            {
              "universoAuditavelId": 3,
              "tipoUniversoAuditavelId": 1,
              "tipoUniversoAuditavelNome": "Ênfases",
              "indicadorId": 62,
              "indicadorDescricao": "Indicador para anexar os critérios utilizados para Mapeamento do Universo Auditável",
              "criterioId": 130,
              "criterioNome": "Ações orçamentárias envolvidas",
              "tipoUniversoAuditavelItemId": 154,
              "tipoUniversoAuditavelItemNome": "Elaboração de ETP e TR de Manutenção",
              "ordem": 1,
              "tipo": 3,
              "idItemPai": 153,
              "universoAuditavelItemRespostas": "3",
              "itens": [],
              "ordemExibicao": "1.2.1"
            },
            {
              "universoAuditavelId": 3,
              "tipoUniversoAuditavelId": 1,
              "tipoUniversoAuditavelNome": "Ênfases",
              "indicadorId": 62,
              "indicadorDescricao": "Indicador para anexar os critérios utilizados para Mapeamento do Universo Auditável",
              "criterioId": 130,
              "criterioNome": "Ações orçamentárias envolvidas",
              "tipoUniversoAuditavelItemId": 155,
              "tipoUniversoAuditavelItemNome": "Elaboração de Minuta de Contrato",
              "ordem": 2,
              "tipo": 3,
              "idItemPai": 153,
              "universoAuditavelItemRespostas": "4",
              "itens": [],
              "ordemExibicao": "1.2.2"
            }
          ],
          "ordemExibicao": "1.2"
        }
      ],
      "ordemExibicao": "1"
    },
    {
      "universoAuditavelId": 3,
      "tipoUniversoAuditavelId": 1,
      "tipoUniversoAuditavelNome": "Ênfases",
      "indicadorId": 62,
      "indicadorDescricao": "Indicador para anexar os critérios utilizados para Mapeamento do Universo Auditável",
      "tipoUniversoAuditavelItemId": 150,
      "tipoUniversoAuditavelItemNome": "Aquisição de Bens e Serviços de TI EDIÇÃO",
      "ordem": 1,
      "tipo": 2,
      "idItemPai": 149,
      "universoAuditavelItemRespostas": "",
      "itens": [
        {
          "universoAuditavelId": 3,
          "tipoUniversoAuditavelId": 1,
          "tipoUniversoAuditavelNome": "Ênfases",
          "indicadorId": 62,
          "indicadorDescricao": "Indicador para anexar os critérios utilizados para Mapeamento do Universo Auditável",
          "criterioId": 130,
          "criterioNome": "Ações orçamentárias envolvidas",
          "tipoUniversoAuditavelItemId": 151,
          "tipoUniversoAuditavelItemNome": "Elaboração de ETP e TR de Bens e Serviços de TI",
          "ordem": 1,
          "tipo": 3,
          "idItemPai": 150,
          "universoAuditavelItemRespostas": "1",
          "itens": [],
          "ordemExibicao": "2.1"
        },
        {
          "universoAuditavelId": 3,
          "tipoUniversoAuditavelId": 1,
          "tipoUniversoAuditavelNome": "Ênfases",
          "indicadorId": 62,
          "indicadorDescricao": "Indicador para anexar os critérios utilizados para Mapeamento do Universo Auditável",
          "criterioId": 130,
          "criterioNome": "Ações orçamentárias envolvidas",
          "tipoUniversoAuditavelItemId": 152,
          "tipoUniversoAuditavelItemNome": "Elaboração de Minuta de Contrato EDIÇÃO",
          "ordem": 2,
          "tipo": 3,
          "idItemPai": 150,
          "universoAuditavelItemRespostas": "2",
          "itens": [],
          "ordemExibicao": "2.2"
        }
      ],
      "ordemExibicao": "2"
    }
  ];

  dataSource: MatTableDataSource<IUniversoAuditavelItemRespostaItensModel>;
  displayedColumns: string[] = ['subtipo', 'macroprocesso', 'processos', 'indicador'];
  criterios: string[] = [];

  // Mapa para armazenar a contagem de ocorrências dos Subtipos e Macroprocessos
  rowSpanMap: Map<string, number> = new Map();


  async ngOnInit() {
    const res = await this.universoAuditavelService.obterPorId(this.id);
    if ( res.sucesso){
      this.modelUniversoAuditavel = res.dados;
    }

    this.buscarTipoUniverso(this.modelUniversoAuditavel.tipoUniversoAuditavelId);
  }






  getPadding(element: IUniversoAuditavelItemRespostaItensModel): number {
    return (element.ordem || 0) * 20;
  }

  getCriterioValue(element: IUniversoAuditavelItemRespostaItensModel, criterioNome: string): string {
    if (element.criterioNome === criterioNome) {
      return element.universoAuditavelItemRespostas;
    }
    return '';
  }

  public async buscarTipoUniverso(idTipo:number){
    const res = await this.tipoUniversoService.obterPorId(idTipo);
    if(res.sucesso){
      this.modelTipoUniverso =  res.dados;
      this.unidadeGestora  = this.modelUniversoAuditavel.unidadeGestoraId;
      this.titulo = 'Visualizar Mapeamento Universo Auditável'+ this.modelTipoUniverso.nome;

      const unidade = await this.unidadeGestoraService.obterPorId(this.unidadeGestora);

      if (unidade.sucesso) {
        this.unidadeGestoraNome = unidade.dados.sigla + '-' + unidade.dados.nome;
      }
    }

    this.buscarItens(this.modelTipoUniverso.id, this.unidadeGestora );
  }


  public async buscarItens(idTipoUniverso: number, idUG: number) {

    if (idTipoUniverso) {
      const res = await this.universoAuditavelItemService.ObterRespostaPorCriterios(idUG, idTipoUniverso);
      if (res.sucesso) {
        this.modelTipoUniversoItens = res.dados;
        this.reordenar();
        this.processData();

      }
    }
  }
  processData() {
    if (!this.modelTipoUniversoItens || Object.keys(this.modelTipoUniversoItens).length === 0) {
      this.dataSource.data = [];
      return;
    }

    const criteriosSet = new Set<string>();
    Object.values(this.modelTipoUniversoItens).forEach((value, idx) => {
      this.processItemsForCriterios(value, criteriosSet);
    });

    this.criterios = Array.from(criteriosSet);
    console.log(this.criterios);

    this.displayedColumns = [
      'subtipo',
      'macroprocesso',
      'processos',
      'indicador',
      ...this.criterios.map(c => `criterio_${c}`)
    ];
    const flattenedData = this.flattenData(Object.values(this.modelTipoUniversoItens));
    console.log(flattenedData);
    this.dataSource.data = flattenedData;

  }
  flattenData(items: IUniversoAuditavelItemRespostaItensModel[]): IUniversoAuditavelItemRespostaItensModel[] {
    const result: IUniversoAuditavelItemRespostaItensModel[] = [];
    let currentIndicadorDescricao = '';

    // Função auxiliar para processar cada item
    const processItem = (item: IUniversoAuditavelItemRespostaItensModel) => {
      // Cria um novo item formatado baseado no tipo
      const formattedItem: IUniversoAuditavelItemRespostaItensModel = {
        ...item,
        // Limpa os valores das colunas
        tipoUniversoAuditavelItemNome: '',
        tipoUniversoAuditavelNome: '',
        ordemExibicao: '',
        indicadorDescricao: ''
      };

      // Se o indicadorDescricao é diferente do atual, atualiza e exibe
      if (item.indicadorDescricao !== currentIndicadorDescricao) {
        currentIndicadorDescricao = item.indicadorDescricao;
        formattedItem.indicadorDescricao = currentIndicadorDescricao;
      }

      // Distribui o valor na coluna correta baseado no tipo
      switch (item.tipo) {
        case 1:
          formattedItem.tipoUniversoAuditavelItemNome = item.tipoUniversoAuditavelItemNome;
          break;
        case 2:
          formattedItem.tipoUniversoAuditavelNome = item.tipoUniversoAuditavelItemNome;
          break;
        case 3:
          formattedItem.ordemExibicao = item.ordemExibicao;
          break;
      }

      result.push(formattedItem);

      // Processa os itens filhos se existirem
      if (item.itens && Object.keys(item.itens).length > 0) {
        Object.values(item.itens).forEach(childItem => {
          processItem(childItem);
        });
      }
    };

    // Processa cada item do array inicial
    items.forEach(item => processItem(item));

    return result;
  }



  processItemsForCriterios(item: IUniversoAuditavelItemRespostaItensModel, criteriosSet: Set<string>) {
    console.log(item.criterioNome);
    if (item.criterioNome) {
      criteriosSet.add(item.criterioNome);
    }

    if (item.itens && Object.values(item.itens).length > 0) {
      Object.values(item.itens).forEach(value => {
        console.log('value', value);
        this.processItemsForCriterios(value, criteriosSet);
      });
    }
  }


  public reordenar() {
    // Verifica se model é um objeto com as chaves e percorre as chaves
    Object.keys(this.modelTipoUniversoItens).forEach((key, idx) => {
      this.reordenarItem(this.modelTipoUniversoItens[key], idx + 1, '');  // Começa a ordem com uma string vazia
    });
  }

  reordenarItem(item: IUniversoAuditavelItemRespostaItensModel, index: number, prefixo: string) {
    const novaOrdem = prefixo ? `${prefixo}.${index}` : `${index}`;
    item.ordemExibicao = novaOrdem;

    if (item.itens && Object.keys(item.itens).length > 0) {
      Object.keys(item.itens).forEach((key, idx) => {
        this.reordenarItem(item.itens[key], idx + 1, novaOrdem);
      });
    }
  }

  public onBack() {

    this.submit = false;

    this.router.navigateByUrl(`/unidades-gestoras/${this.modelUniversoAuditavel.unidadeGestoraId}?aba=3`);
  }

// Calcula o RowSpan para cada Subtipo
  getRowSpan(subtipo: string): number {
    if (!this.rowSpanMap.has(subtipo)) {
      const count = Object.values(this.dataSource).filter(
        (item: any) => item.tipoUniversoAuditavelItemNome === subtipo
      ).length;

      this.rowSpanMap.set(subtipo, count);
    }
    return this.rowSpanMap.get(subtipo) as number ?? 1;
  }

// Verifica se o Subtipo já foi exibido
  isFirstOccurrence(element: any, index: number): boolean {
    return index === 0 || this.dataSource[index - 1].tipoUniversoAuditavelItemNome !== element.tipoUniversoAuditavelItemNome;
  }

// Calcula o RowSpan para cada Macroprocesso
//   getMacroprocessoRowSpan(macroprocesso: string): number {
//     if (!this.rowSpanMap.has(macroprocesso)) {
//       const count = this.dataSource.filter(item => item.tipoUniversoAuditavelNome === macroprocesso).length;
//       this.rowSpanMap.set(macroprocesso, count);
//     }
//     return this.rowSpanMap.get(macroprocesso) || 1;
//   }

// Verifica se o Macroprocesso já foi exibido
  isFirstMacroprocesso(element: any, index: number): boolean {
    return index === 0 || this.dataSource[index - 1].tipoUniversoAuditavelNome !== element.tipoUniversoAuditavelNome;
  }

}
