import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ITipoUniversoItemModel } from 'src/app/models/tipo-universo.model';

@Component({
  selector: 'app-modal-tipo-universo-item-descricao',
  templateUrl: './modal-tipo-universo-item-descricao.component.html',
})
export class ModalTipoUniversoItemDescricaoComponent implements OnInit {

    public titulo: string = "";
    public model = {} as ITipoUniversoItemModel;

  constructor(
    public matDialog: MatDialog,
    private dialogRef: MatDialogRef<ModalTipoUniversoItemDescricaoComponent>,
    @Inject(MAT_DIALOG_DATA) data) {
    this.data = data;

    this.model = data.item as ITipoUniversoItemModel;

    if(this.model.nome && this.model.nome != ""){
        this.titulo += "Editar ";
    }else{
        this.titulo += "Incluir ";
    }

    this.titulo += this.model.tipoDescription;

  }

  public data = {} as any;
  ngOnInit(): void { }

  public onChangeNome(event){
    this.model.nome = event.target.value;
  }

  Confirmar() {
    let data = {
        item: this.model,
        indx: this.data.indx
    };

    this.dialogRef.close(data);
  }

  Cancelar() {
    this.dialogRef.close(false);
  }
}
