import { BaseListComponent } from "../../../shared/components/base-list/base-list.component";
import { Component, ViewChild } from "@angular/core";
import { IQuestionarioUGListaModel } from "../../../models/questionario-ug-lista.model";
import { IUniversoAuditavelModel } from "../../../models/universo-auditavel.model";
import { AuthService } from "../../../services/auth.service";
import { QuestionarioUGService } from "../../../services/questionarioug.service";
import { Router } from "@angular/router";
import { MatDialog, MatDialogConfig, MatDialogRef } from "@angular/material/dialog";
import { UnidadeGestoraService } from "../../../services/unidade-gestora.service";
import { SharedService } from "../../../services/shared.service";
import { FiltroUniversoAuditavel } from "../../../models/filtro-universo-auditavel";
import { MatTableDataSource } from "@angular/material/table";
import { UniversoAuditavelService } from "../../../services/universo-auditavel.service";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { IPermissaoModel } from "../../../models/permissao.model";
import { EnumRecursoPerfil } from "../../../models/enum/recurso-perfil.enum";
import { EnumAcaoRecurso } from "../../../models/enum/acao-recurso.enum";
import { EnumTipoQuestionarioUG } from "../../../models/enum/tipo-questionario-ug.enum";
import {
  ModalUniversoAuditavelNovaVersaoComponent
} from "../shared/modal-universo-auditavel-nova-versao/modal-universo-auditavel-nova-versao.component";
import {
  EQuestionarioUGSituacao,
  EQuestionarioUGSituacaoDescricao
} from "../../../models/enum/questionario-ug-situacao.enum";
import {
  EUniversoAuditavelSituacao,
  EUniversoAuditavelSituacaoDescricao
} from "../../../models/enum/universo-auditavel-situacao";
import {
  ModalSolicitarPreenchimentoUniversoComponent
} from "./modal-solicitar-preenchimento/modal-solicitar-preenchimento.component";
import { ModalSelecaoTipoUniversoComponent } from "./modal-selecao-tipo-universo/modal-selecao-tipo-universo.component";


const sortingDataAccessor = (item, property) => {
  switch (property) {
    case 'versao': return item.versao.trim().toLowerCase();
    case 'data': return item.data.trim().toLowerCase();
    case 'situacao': return item.situacao.trim().toLowerCase();
    case 'ativo':
      return item.ativo;
    default:
      return item.versao;
  }
};

@Component({
  selector: 'app-aba-universo-auditavel',
  templateUrl: './aba-univserso-auditavel.component.html',
  styleUrls: ['./aba-univserso-auditavel.component.scss']
})

export class AbaUniversoAuditavelComponent extends  BaseListComponent{

  public selectedModel: IUniversoAuditavelModel;
  public dataSource = new MatTableDataSource<IUniversoAuditavelModel>([]);
  public filtro =  {} as FiltroUniversoAuditavel;
  public botaoImportarDisable = false;
  public pageEvent: any;
  public totalItens: number;
  public displayedColumns: string[] = ['selecao', 'versao', 'nomeTipoUniversoAuditavel', 'dataCriacaoFormatada' , 'ativo', 'status'];
  public semDados = true;
  public botaoImprimirDisable = false;
  public botaoExcluirDisable = false;
  public botaoVisualizarDisable = false;
  public botaoEditarDisable = false;
  public botaoNovoDisable = false;
  public permissoes: IPermissaoModel;
  private currentDialogRef: MatDialogRef<any>;
  tipoUniversoId: number;
  tipoUniverso: number;



  @ViewChild('TableOnePaginator', { static: true }) tableOnePaginator: MatPaginator;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  optionsSituacao = [
    { id: undefined, nome: 'Todos' },
    { id: 1, nome: 'Em elaboração' },
    { id: 2, nome: 'Em preenchimento' },
    { id: 3, nome: 'Revisão' },
    { id: 4, nome: 'Pendente' },
    { id: 5, nome: 'Avocado' },
    { id: 6, nome: 'Expirado' },
    { id: 7, nome: 'Finalizado' },
  ];

  optionsTipoUniverso = [
    { id: undefined, nome: 'Todos' },
    { id: 1, nome: 'Elaborado' },
    { id: 2, nome: 'Não Elaborado ' },
  ];

  constructor(
    private authService: AuthService,
    private questionarioUGService: QuestionarioUGService,
    private router: Router,
    private matDialog: MatDialog,
    private universoAuditavelService: UniversoAuditavelService,
    private sharedService: SharedService
  ) {
    super();

  }

  ngOnInit(): void {
    this.setarPermissoes();
    this.configurarFiltro();
  }

  async ngAfterViewInit() {

    this.buscar();
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
    this.dataSource.sortingDataAccessor = sortingDataAccessor;
  }
  private setarPermissoes(): void {
    this.permissoes = new IPermissaoModel();
    this.permissoes.consultar = this.authService.possuiPermissao(EnumRecursoPerfil.QuestionarioMaturidade, EnumAcaoRecurso.Consultar);
    this.permissoes.editar = this.authService.possuiPermissao(EnumRecursoPerfil.QuestionarioMaturidade, EnumAcaoRecurso.Editar);
    this.permissoes.excluir = this.authService.possuiPermissao(EnumRecursoPerfil.QuestionarioMaturidade, EnumAcaoRecurso.Excluir);
    this.permissoes.inativarReativar = this.authService.possuiPermissao(EnumRecursoPerfil.QuestionarioMaturidade, EnumAcaoRecurso.AtivarInativar);
    this.permissoes.incluir = this.authService.possuiPermissao(EnumRecursoPerfil.QuestionarioMaturidade, EnumAcaoRecurso.Inserir);
    this.permissoes.visualizar = this.authService.possuiPermissao(EnumRecursoPerfil.QuestionarioMaturidade, EnumAcaoRecurso.Visualizar);
    this.permissoes.importar = this.authService.possuiPermissao(EnumRecursoPerfil.QuestionarioMaturidade, EnumAcaoRecurso.Importar);
    this.permissoes.imprimir = this.authService.possuiPermissao(EnumRecursoPerfil.QuestionarioMaturidade, EnumAcaoRecurso.Imprimir);

  }


  private configurarFiltro() {
    this.filtro.unidadeGestoraId = this.sharedService.getId();
  }

  public editar(id: number) {
    if(!this.permissoes.editar){
      this.exibirMensagemErro("Você não possui permissão para esta ação.");
      return;
    }

    this.router.navigate([`/universo-auditavel-form/${id}`]);
  }
  public excluir(id: number) {}
  public novo() {
    if(!this.permissoes.incluir){
      this.exibirMensagemErro("Você não possui permissão para Incluir.");
      return;
    }
    this.sharedService.setId(this.filtro.unidadeGestoraId);
    this.router.navigate(['tipo-universo-auditavel/novo']);
  }
  public visualizar(id: number) {
    this.router.navigate([`visualizar-universo-auditavel/${id}`]);
  }
  public imprimir(id: number) {}
  public preencher(id: number) {

    const situacaoAtual = this.selectedModel.status;

    const situacoesNaoPermitidas = [
      EUniversoAuditavelSituacao[EUniversoAuditavelSituacao.Pendente],
      EUniversoAuditavelSituacao[EUniversoAuditavelSituacao.Finalizado]
    ];

    if(!situacoesNaoPermitidas.includes(situacaoAtual) && this.selectedModel.ativo == true){
      this.router.navigate([`universo-auditavel-preechimento-form`]);

    }else{
      this.exibirMensagemAlerta('Universo só pode ser preenchido se estiver com Status diferente de Finalizado ou Pendente e também que esteja ativo.');
    }
    this.sharedService.setParams(this.selectedModel.unidadeGestoraId, id, this.selectedModel.tipoUniversoAuditavelId );

    }
  public solicitar(id: number) {

    const situacoesPermitidas = [
      EUniversoAuditavelSituacaoDescricao[EUniversoAuditavelSituacao.EmElaboracao],
      EUniversoAuditavelSituacaoDescricao[EUniversoAuditavelSituacao.Avocado]
    ];
    const situacaoAtual = this.selectedModel.status;

    if(situacoesPermitidas.includes(situacaoAtual) && this.selectedModel.ativo == true){
      const dialogConfig = new MatDialogConfig();
      dialogConfig.id = 'modal-solicitar-preenchimento';
      dialogConfig.width = '650px';
      dialogConfig.hasBackdrop = true;
      dialogConfig.disableClose = true;
      dialogConfig.data = {
        param1: id
      };

      this.currentDialogRef =  this.matDialog.open(ModalSolicitarPreenchimentoUniversoComponent, dialogConfig);
      this.currentDialogRef.afterClosed().subscribe((data) => {
        this.buscar();
      });

    }


  }
  public avocar(id: number) {
    const situacao = EUniversoAuditavelSituacao.Pendente;
    if (this.selectedModel.status == EQuestionarioUGSituacaoDescricao[situacao] && this.selectedModel.ativo == true){
     this.universoAuditavelService.Avocar(id).then(res=>{
       if (res.sucesso) {
         this.exibirMensagemSucesso('Universo Auditável avocado com sucesso.');
       }else {
         this.exibirMensagemAlerta(res.mensagem.descricao);
       }
     }).catch((err) => {
       this.exibirMensagemErro(err.mensagem);
     }).finally(() => {
       this.buscar();
     });
   }

  }
  public avaliar(id: number) {}
  public buscar() {

    this.selectedModel = null;

    this.universoAuditavelService.obter(this.filtro).then((res)=>{
      this.totalItens = res.dados.totalItens;
      // @ts-ignore
      this.dataSource = new MatTableDataSource<IUniversoAuditavelModel>(res.dados.resultado);
      this.paginator = this.tableOnePaginator;
      this.dataSource.sort = this.sort;
      this.dataSource.sortingDataAccessor = sortingDataAccessor;
      this.dataSource._updateChangeSubscription();
      this.semDados = this.dataSource.filteredData.length === 0;

    }).catch((err)=> {
      this.exibirMensagemErro(err.mensagem);
    });
  }
  public importarArquivo() {

      const dialogConfig = new MatDialogConfig();
      dialogConfig.id = 'modal-component';
      dialogConfig.width = '650px';
      dialogConfig.hasBackdrop = true;
      dialogConfig.disableClose = true;
      dialogConfig.data = {
      router: 'importar-arquivo-universo-auditavel',
      unidadeGestora: this.filtro.unidadeGestoraId
    };

      const modal = this.matDialog.open(ModalSelecaoTipoUniversoComponent , dialogConfig );

      modal.afterClosed().subscribe((data) => {
        if(data){
          this.filtro.pesquisa = data.nome;
          this.tipoUniversoId = data.id;
          this.tipoUniverso = data.tipo;
        }
      })
  }

  pageChanged(e) {
    this.filtro.pagina = e.pageIndex + 1;
    e.pageIndex = this.filtro.pagina;
    this.filtro.itensPorPagina = e.pageSize;
    this.buscar();
  }

  public selecionar(item: any) {
    this.selectedModel = !this.selectedModel || this.selectedModel.id !== item.id ? item : null;
  }
  public isButtonDisabled(): boolean {
    // Verifica se existe um modelo selecionado
    if (!this.selectedModel) {
      return true;
    }

    // Verifica as condições adicionais
    return !this.selectedModel.ativo ||
      !(this.selectedModel.status === 'Em Elaboração' || this.selectedModel.status === 'Avocado'  || this.selectedModel.status === 'Em revisão'  || this.selectedModel.status === 'Em preenchimento');
  }

  sortData(sort) {
    this.filtro.colunaOrder = sort.active;
    this.filtro.direcao = sort.direction;

    this.buscar();
  }
  isSelect() {
    if (this.selectedModel !== null) {
      return !this.selectedModel?.ativo;
    }
  }

  public isButtonDisabledEditar(): boolean { return true}
}
