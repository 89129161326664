import { BaseFormComponent } from "../../../../shared/components/base-form/base-form.component";
import { AfterViewInit, Component, EventEmitter, Inject, OnInit, Output, ViewChild } from "@angular/core";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { FiltroModel } from "../../../../models/filtro.model";
import { ActivatedRoute, Router } from "@angular/router";
import { MAT_DIALOG_DATA, MatDialog, MatDialogConfig, MatDialogRef } from "@angular/material/dialog";
import { ToastrService } from "ngx-toastr";
import { BsLocaleService } from "ngx-bootstrap/datepicker";
import { TipoUniversoService } from "../../../../services/tipo-universo.service";
import { ITipoUniversoModel } from "../../../../models/tipo-universo.model";
import { MatTableDataSource } from "@angular/material/table";
import { defaultDataAcessor } from "../../../../core/helpers/mat-sort-data-accessor";
import { IFiltroTipoUniverso } from "../../../../models/filtros/filtro-tipo-universo.model";
import { IModeloQuestionarioModel } from "../../../../models/modelo-questionario.model";
import { IUniversoAuditavelModel } from "../../../../models/universo-auditavel.model";
import { UniversoAuditavelService } from "../../../../services/universo-auditavel.service";
import { FiltroUniversoAuditavel } from "../../../../models/filtro-universo-auditavel";
import { MatCheckboxChange } from "@angular/material/checkbox";
import {
  ModalUniversoAuditavelImportacaoComponent
} from "../../shared/modal-universo-auditavel-importacao/modal-universo-auditavel-importacao.component";
import {
  ModalUniversoAuditavelNovaVersaoComponent
} from "../../shared/modal-universo-auditavel-nova-versao/modal-universo-auditavel-nova-versao.component";


@Component({
  selector: 'app-modal-selecao-tipo-universo',
  templateUrl: './modal-selecao-tipo-universo.component.html',
  styleUrls: ['./modal-selecao-tipo-universo.component.scss']
})
export class ModalSelecaoTipoUniversoComponent extends BaseFormComponent implements AfterViewInit, OnInit{
  @ViewChild('TableOnePaginator', { static: true }) tableOnePaginator: MatPaginator;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @Output() nomeSelecionado = new EventEmitter<string>();
  public filtro = {} as IFiltroTipoUniverso;
  public filtroUniverso = {} as FiltroUniversoAuditavel;
  public selecionado: ITipoUniversoModel;
  public totalItens: number;
  public tipoUniversoId: number;
  public tipoUniverso: number;
  public displayedColumns: string[] = ['selecao', 'nome'];
  public semDados = true;
  public dataSource = new MatTableDataSource<ITipoUniversoModel>([]);
  private currentDialogRef: MatDialogRef<any>;
  constructor(
    route: ActivatedRoute,
    private dialogRef: MatDialogRef<ModalSelecaoTipoUniversoComponent>,
    public toastr: ToastrService,
    localeService: BsLocaleService,
    router: Router,
    private tipoUniversoService: TipoUniversoService,
    private universoService: UniversoAuditavelService,
    public matDialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data

  ) {
    super(route, toastr, router, localeService, matDialog);
  }

  ngAfterViewInit(): void {

    }

  ngOnInit(): void {
    this.filtro.ativo = true;
    this.buscarTipoUniverso();
  }

  public async concluir() {
    if(this.data !== null){
      this.filtroUniverso.unidadeGestoraId =  this.data.unidadeGestora;
      this.filtroUniverso.tipoUniversoAuditavelId = this.tipoUniversoId;
      this.filtroUniverso.ativo = true;

      const retorno =   await  this.universoService.obter(this.filtroUniverso);

      if(retorno.dados.resultado.length > 0){
        this.dialogRef.close();
        const dialogConfig = new MatDialogConfig();
        dialogConfig.id = 'modal-component-importar';
        dialogConfig.width = '650px';
        dialogConfig.hasBackdrop = true;
        dialogConfig.disableClose = true;
        dialogConfig.data = {
          source: 'importar-universo-auditavel-novo',
          unidadeGestoraId: this.filtroUniverso.unidadeGestoraId,
          tipoUniversoId: this.tipoUniversoId,
          tipoUniverso: this.tipoUniverso,

        };
        const modal= this.matDialog.open(ModalUniversoAuditavelImportacaoComponent, dialogConfig);
        modal.afterClosed().subscribe((data) => {

        });
      }else{
        this.router.navigate(['/importar-arquivo-universo-auditavel/novo'], { queryParams: { unidadeGestoraId: this.filtroUniverso.unidadeGestoraId, tipoUniversoId: this.tipoUniversoId } });
        this.dialogRef.close(this.selecionado);
      }
    }else{
      this.dialogRef.close(this.selecionado);
    }

  }

  public buscarTipoUniverso(){
    this.tipoUniversoService
      .obter(this.filtro)
      .then((res) => {
        if(res.sucesso){
          this.totalItens = res.dados.totalItens;
          this.dataSource = new MatTableDataSource<ITipoUniversoModel>(res.dados.resultado);
          this.totalItens = res.dados.totalItens;
          this.paginator = this.tableOnePaginator;
          this.dataSource.sort = this.sort;
          this.dataSource.sortingDataAccessor = defaultDataAcessor;
          this.dataSource._updateChangeSubscription();
          this.semDados = this.dataSource.filteredData.length === 0;
        }
      }).catch((err) => {
      this.exibirMensagemErro(err);
    });
  }
  public selecionar(item: ITipoUniversoModel) {
    this.selecionado = !this.selecionado || this.selecionado.id !== item.id ? item : null;
    this.tipoUniversoId = item.id;
    this.tipoUniverso =  item.tipo;
  }

  onCheckboxChange(event: MatCheckboxChange, element: any){
    if (event.checked){
      this.selecionado = !this.selecionado || this.selecionado.id !== element.id ? element : null;
      this.nomeSelecionado = element.nome;
      this.tipoUniversoId =  element.id;
      this.tipoUniverso =  element.tipo;
    }
  }

  public filtrar(event: Event){
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
    this.semDados = this.dataSource.filteredData.length === 0;
  }
  public cancelar() {
    this.dialogRef.close(null);
  }
}
