import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';
import { ToastrService } from 'ngx-toastr';
import { IBaseModel } from 'src/app/models/base.model';
import { BaseFormComponent } from '../../shared/components/base-form/base-form.component';
import Swal from 'sweetalert2';
import { ModalCancelarComponent } from 'src/app/shared/components/modal-cancelar/modal-cancelar-component';
import { ITipoUniversoModel, ITipoUniversoUGModel } from 'src/app/models/tipo-universo.model';
import { TipoUniversoService } from 'src/app/services/tipo-universo.service';
import { IndicadorService } from 'src/app/services/indicador.service';
import { MatTableDataSource } from '@angular/material/table';
import { ModalTipoUniversoItemComponent } from './modal-tipo-universo-item/modal-tipo-universo-item.component';

@Component({
  selector: 'app-tipo-universo-form',
  templateUrl: './tipo-universo-form.component.html',
  styleUrls: ['./tipo-universo-form.component.scss']
})
export class TipoUniversoFormComponent extends BaseFormComponent implements OnInit {
  public model: ITipoUniversoModel = {} as ITipoUniversoModel;
  public ugsDataSource = new MatTableDataSource<ITipoUniversoUGModel>([]);
  public semDados = true;
  public idTipoClassificacao: number;


  public form = new FormGroup({
    id: new FormControl({ value: '', disabled: true }),
    nome: new FormControl('', Validators.required),
    descricao: new FormControl('', Validators.required),
    tipo: new FormControl(null, Validators.required),
    indicadorId: new FormControl(null, Validators.required)
  });

  constructor(
    route: ActivatedRoute,
    toastr: ToastrService,
    router: Router,
    localeService: BsLocaleService,
    private tipoUniversoService: TipoUniversoService,
    private indicadorService: IndicadorService,
    public matDialog: MatDialog
  ) {
    super(route, toastr,  router, localeService, matDialog);

    if (this.novoRegistro) {
      this.titulo = 'Novo Tipo de universo auditável';
    }
  }

  async ngOnInit() {
    try {
      if (!this.novoRegistro) {
        const res = await this.tipoUniversoService.obterPorId(this.id);
        if (res.sucesso) {
          this.model = res.dados;
          this.idTipoClassificacao = this.model.tipo;

          this.ugsDataSource = new MatTableDataSource<ITipoUniversoUGModel>(this.model.ugs);
          this.semDados = this.ugsDataSource.filteredData.length === 0;
          if(this.semDados){
            if(this.model.tipo == 1){
              this.alterarParaTipoPadronizado();
            }
          }

          if (this.visualizar){
            this.titulo = 'Visualizar Tipo de universo auditável - ' + this.model.nome;
          }
          else {
            this.titulo = 'Editar Tipo de universo auditável  - ' + this.model.nome;
          }
        } else {
          this.exibirMensagemErro(res.mensagem.descricao);
          this.router.navigate(['/tipo-universo']);
          return;
        }
      }

      this.form.patchValue(this.model);
    } catch (err) {
      this.exibirMensagemErro(err);
      this.router.navigate(['/tipo-universo']);
    }
  }

  public async salvarDados(salvarEFechar: boolean){
    if (this.form.invalid) {
      this.exibirMensagemAlerta('Formulário inválido');
      return;
    }
    this.submit = true;

    this.atualizarModel(this.form.value);
    this.model.tipo = this.idTipoClassificacao;

    try {
      let res: IBaseModel<any> = null;

      if (!this.novoRegistro) {
        res = await this.tipoUniversoService.atualizar(this.model);
      } else {
        res = await this.tipoUniversoService.inserir(this.model);
      }

      if (res.sucesso) {
        this.exibirMensagemSucesso(res.mensagem.descricao);

        if(salvarEFechar == true)
          this.router.navigate(['/tipo-universo']);
        else
          if(this.novoRegistro){
            this.router.navigate([`/tipo-universo/${res.dados.id}`]);
            await this.ngOnInit();
          }
      } else {
        this.exibirMensagemErro(res.mensagem.descricao);
      }
    } catch (err) {
      this.exibirMensagemErro(err);
    } 

  }

  public async exibirModalIndicador() {
    try {
      const res = await this.indicadorService.obter('', true);
      if (res.sucesso) {
        res.dados.sort((a, b) => a['nome'].localeCompare(b['nome']));
        const modal = this.exibirModal('Selecionar Indicador', res.dados);
        modal.afterClosed().subscribe((data) => {
          if (data) {
            //this.salvarDadosPendente = true;
            this.model.indicadorNome = data.nome;
            this.model.indicadorId = data.id;
            this.form.controls['indicadorId'].setValue(data.id);
          }
        });
      } else {
        this.exibirMensagemErro(res.mensagem.descricao);
      }
    } catch (err) {
      this.exibirMensagemErro(err.mensagem.descricao);
    }
  }
  
  private atualizarModel(values: any) {
    Object.assign(this.model, values);
  }

  public abrirModalUG(ug: ITipoUniversoUGModel, visualizar: boolean){
    
    const dialogConfig = new MatDialogConfig();
    dialogConfig.id = 'modal-component-universo-item';
    dialogConfig.width = '1000px';
    dialogConfig.hasBackdrop = true;
    dialogConfig.disableClose = true;
    dialogConfig.data = {
          idTipoUniverso: this.id,
          idClassificacaoTipo: this.idTipoClassificacao,
          idUG: ug ? ug.idUG : null,
          visualizar: visualizar,
          tipoDescription: this.model.tipoDescription,
          nomeUG: ug ? ug.nome : "",
          idsUgsExistentes: this.model.ugs ? this.model.ugs.map(item => item.idUG) : null
    };

    const modal = this.matDialog.open(ModalTipoUniversoItemComponent, dialogConfig);

    modal.afterClosed().subscribe((data) => {
      if(data){
        this.ngOnInit();
      }
    })
  }

  public excluirUG(idUG: number){

    Swal.fire({
      title: 'Excluir subtipo de Universo Auditável',
      text: `Esta ação excluirá todo o subtipo de universo auditável. Será necessário realizar um novo cadastro de subtipo de universo auditável. Tem certeza que deseja excluí-lo?`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não',
    }).then(resConfirm => {
      if (resConfirm.isConfirmed) {
        this.tipoUniversoService.excluirUG(this.id, idUG)
        .then((res) => {
          if(res.sucesso){
            this.exibirMensagemSucesso(res.mensagem.descricao);
            this.ngOnInit();
          }else{
            this.exibirMensagemErro(res.mensagem.descricao);
          }
        })
        .catch(err => this.exibirMensagemErro(err))
      }
    })


  }

  public onChangeSelectTipo(event: any) {

    if(!this.semDados){
      Swal.fire({
        title: 'Alterar classificação',
        text: `Esta ação irá apagar os subtipos cadastrados. Deseja realmente continuar?`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Sim',
        cancelButtonText: 'Não',
      }).then(resConfirm => {
        if (resConfirm.isConfirmed) {
  
          this.idTipoClassificacao = event.target.value;
  
          if(this.idTipoClassificacao == 1){
            
            this.alterarParaTipoPadronizado();

          }else{
            
            this.alterarParaTipoNaoPadronizado();

          }
  
        }else{
          
          this.model.tipo = this.idTipoClassificacao;
          
        }
      });
    }else{
      this.idTipoClassificacao = event.target.value;

      if(this.idTipoClassificacao == 1){
            
        this.alterarParaTipoPadronizado();

      }else{
        
        this.alterarParaTipoNaoPadronizado();
        
      }
    }

	}

  private alterarParaTipoPadronizado(){
    let novoItem = {} as ITipoUniversoUGModel;
    let listaItens = [] as ITipoUniversoUGModel[];
    listaItens.push(novoItem);
    
    this.model.tipo = 1;
    this.model.tipoDescription = "Padronizada";
    this.model.ugs = null;
    this.model.ugs = listaItens;
    this.ugsDataSource = new MatTableDataSource<ITipoUniversoUGModel>(this.model.ugs);
    this.semDados = false;
    this.form.controls['tipo'].setValue(this.model.tipo);
  }

  private alterarParaTipoNaoPadronizado(){
    this.model.tipo = 2;
    this.model.tipoDescription = "Não-Padronizada";
    this.model.ugs = null;
    this.ugsDataSource = new MatTableDataSource<ITipoUniversoUGModel>(this.model.ugs);
    this.semDados = true;
    this.form.controls['tipo'].setValue(this.model.tipo);
  }

  public onBack() {
    if(this.form.dirty){
      const dialogConfig = new MatDialogConfig();
      
      const modal = this.matDialog.open(ModalCancelarComponent, dialogConfig);
      modal.afterClosed().subscribe((resultado) => {
        if (resultado == true) {
          this.router.navigate(['/tipo-universo']);
        }
      });

    }else{
      this.router.navigate(['/tipo-universo']);
    }
  }
  
  public cancelar() {
    this.router.navigate(['/tipo-universo']);
  }
}
