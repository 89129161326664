import { BaseFormComponent } from "../../../../shared/components/base-form/base-form.component";
import { ChangeDetectorRef, Component, OnInit } from "@angular/core";
import { SharedService } from "../../../../services/shared.service";
import { ActivatedRoute, Route, Router } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { BsLocaleService } from "ngx-bootstrap/datepicker";
import { TipoUniversoService } from "../../../../services/tipo-universo.service";
import { UniversoAuditavelService } from "../../../../services/universo-auditavel.service";
import { AuthService } from "../../../../services/auth.service";
import { MatDialog } from "@angular/material/dialog";
import {
  IUniversoAuditavelItemRespostaModel,
  IUniversoAuditavelModel
} from "../../../../models/universo-auditavel.model";
import {
  ITipoUniversoItemModel,
  ITipoUniversoModel,
  ITipoUniversoUGModel
} from "../../../../models/tipo-universo.model";
import { UniversoAuditavelItemRespostaService } from "../../../../services/universo-auditavel-item-resposta.service";
import { TipoUniversoEnum } from "../../../../models/enum/tipo-universo.enum";
import { UnidadeGestoraService } from "../../../../services/unidade-gestora.service";
import { FormGroup, FormBuilder, Validators, FormControl } from "@angular/forms";
import Swal from "sweetalert2";


@Component({
  selector: 'app-universo-auditavel-preechimento-form',
  templateUrl: './universo-auditavel-preechimento-form.component.html',
  styleUrls: ['./universo-auditavel-preechimento-form.component.scss']
})
export class  UniversoAuditavelPreechimentoFormComponent extends BaseFormComponent implements OnInit{

  public titulo: string;
  public modelUniversoAuditavel : IUniversoAuditavelModel = {} as IUniversoAuditavelModel;
  public modelTipoUniverso : ITipoUniversoModel = {} as ITipoUniversoModel;
  public modelTipoUniversoItens : ITipoUniversoUGModel = {} as ITipoUniversoUGModel;
  public semDados: boolean;
  public respostaItens: IUniversoAuditavelItemRespostaModel[] = [];

  public dropdownData: { value: string, text: string , unidade: string}[] = [];
  formGroup: FormGroup;
  criteriosResposta: { [key: string]: IUniversoAuditavelItemRespostaModel[] } = {};
  errorMessage: string | null = null;
  errorMessageForm: boolean = false;
  unidadeSelecionada: string = '';
  unidadeGestora: number;
  universoAuditavel: number;
  tipoUniversoAuditavel: number;
  selectedDropdownValue: string = '';
  unidadeGestoraNome: string = '';
  submitted = false;
  habilitaBotaoFinalizar: boolean = false;


  constructor(
    private authService: AuthService,
    public matDialog: MatDialog,
    private universoAuditavelService: UniversoAuditavelService,
    private tipoUniversoService: TipoUniversoService,
    private universoAuditavelItemRespostaService: UniversoAuditavelItemRespostaService,
    private sharedService: SharedService,
    private unidadeGestoraService: UnidadeGestoraService,
    route: ActivatedRoute,
    toastr: ToastrService,
    router: Router,
    localeService: BsLocaleService,
    private cdr: ChangeDetectorRef,
    private fb: FormBuilder
  ) {
    super(route, toastr, router, localeService, matDialog);

    const params =  this.sharedService.getParams();

    this.universoAuditavel = params.universoId;
    this.unidadeGestora = params.unidadeGestoraId;
    this.tipoUniversoAuditavel = params.tipouniversoId;
  }

  async ngOnInit() {

    this.formGroup = this.fb.group({});

    const res = await this.universoAuditavelService.obterPorId(this.universoAuditavel);
    if(res.sucesso){
      this.modelUniversoAuditavel = res.dados;
    }

    this.buscarTipoUniverso(this.tipoUniversoAuditavel);
    this.montaComboUniversoAuditavel();
    this.titulo = "Preencher formulário"
  }

  public  montaComboUniversoAuditavel (){
    this.universoAuditavelService.obterCriterioPorTipo(this.unidadeGestora, this.tipoUniversoAuditavel ).then(res=>{
      if (res.sucesso){
        this.dropdownData = res.dados;
      }
    });
  }

  limparCampos(): void {
    Object.keys(this.formGroup.controls).forEach((controlName) => {
      if (controlName.startsWith('resposta-')) {
        this.formGroup.get(controlName)?.reset();
      }
    });
  }

  public  async  onSelectionChange(event: any) {
    const selectedValue = event.target.value;
    const selectedItem = this.dropdownData.find(item => item.value === selectedValue);
    this.unidadeSelecionada = selectedItem ? selectedItem.unidade : '';
    this.limparCampos();
    this.selectedDropdownValue = selectedValue;

    await  this.buscarRespostaItens(parseInt(selectedValue,10));

    if (!this.criteriosResposta[this.selectedDropdownValue]) {
      // @ts-ignore
      this.criteriosResposta[this.selectedDropdownValue] = [];
    }
  }

  public async buscarRespostaItens(selectedValue: number): Promise<void> {
    try {
      const res = await this.universoAuditavelItemRespostaService.ObterRespostaPorCriterio(
        selectedValue,
        this.unidadeGestora,
        this.tipoUniversoAuditavel
      );
      if (res.dados !== null) {
        this.respostaItens = res.dados;

        this.initializeFormControls();
      }
    } catch (error) {
      console.error("Erro ao buscar itens de resposta:", error);
    }
  }

  initializeFormControls(): void {
    const valoresParaPreencher: any = {};

    this.modelTipoUniversoItens.itens.forEach((subtipo) => {
      subtipo.itens.forEach((macro) => {
        macro.itens.forEach((processo) => {

          const respostaItem = this.respostaItens.find(
            (item) => item.tipouniversoItemId === processo.id
          );

          if (respostaItem) {
            const controleValor = Number(respostaItem.resposta);
            valoresParaPreencher[`resposta-${processo.id}`] = controleValor;
          } else {
            valoresParaPreencher[`resposta-${processo.id}`] = null;
          }
        });
      });
    });

    // Usando setValue para definir todos os valores
    this.formGroup.setValue(valoresParaPreencher);
  }
  public async buscarTipoUniverso(idTipo:number){
    const res = await this.tipoUniversoService.obterPorId(idTipo);
    if(res.sucesso){
      this.modelTipoUniverso =  res.dados;

      this.titulo = 'Editar Mapeamento Universo Auditável ' + this.modelTipoUniverso.nome;

      const unidade = await this.unidadeGestoraService.obterPorId(this.unidadeGestora);

      if (unidade.sucesso) {
        this.unidadeGestoraNome = unidade.dados.sigla + '-' + unidade.dados.nome;
      }
    }

    this.buscarItens(this.tipoUniversoAuditavel, this.modelTipoUniverso.tipo ===  TipoUniversoEnum.Padronizado ? null : this.unidadeGestora);
  }


  gerarRespostas(): any[] {
    if (!this.selectedDropdownValue) {
      this.exibirMensagemAlerta('Nenhum critério selecionado.');
      this.errorMessage = 'Por favor, selecione um Critério!';

      return [];
    }

    const responses: any[] = [];

    this.modelTipoUniversoItens.itens.forEach((subtipo: any) => {
      subtipo.itens.forEach((macro: any) => {
        const macroResponses: IUniversoAuditavelItemRespostaModel[] = [];

        macro.itens.forEach((processo: any) => {
          const processoControl = this.formGroup.get('resposta-' + processo.id);
          if (processoControl?.value === null || processoControl?.value === '') {
            processoControl?.setErrors({ required: true });  // Marca o campo como obrigatório
          } else {
            processoControl?.setErrors(null);  // Remove o erro se o campo tiver valor
          }


          if (processoControl) {
            macroResponses.push({
              resposta: processoControl.value || '',
              criterioId:  parseInt(this.selectedDropdownValue ,10),
              unidadeGestoraId: this.unidadeGestora,
              tipouniversoItemId: processo.id,
              universoAuditavelId : this.universoAuditavel,
              tipouniversoId: this.tipoUniversoAuditavel

            } as IUniversoAuditavelItemRespostaModel);
          } else {
            console.warn(`Controle não encontrado para processo-${processo.id}`);
          }
        });

        if (macroResponses.length > 0) {
          responses.push(macroResponses);
        }
      });
    });

    if(this.criteriosResposta[this.selectedDropdownValue]){
      // @ts-ignore
      this.criteriosResposta[this.selectedDropdownValue] = responses;
    }else{
      // @ts-ignore
      this.criteriosResposta[this.selectedDropdownValue] = responses;
    }
  }

  public async onSubmit(salvarEFechar: boolean = false){
    this.submitted = true;
    this.gerarRespostas();
    if (this.formGroup.invalid) {
      this.exibirMensagemAlerta('Formulário inválido');
      return;
    }
    const allResponses: IUniversoAuditavelItemRespostaModel[] = Object.values(this.criteriosResposta).reduce((acc, curr) => {
      // @ts-ignore
      return acc.concat(curr);
    }, []);
    const itensUniverso: IUniversoAuditavelItemRespostaModel[] = allResponses.reduce((acc, curr) => acc.concat(curr), []);

    const res = await this.universoAuditavelItemRespostaService.inserir(itensUniverso);

    if(res.sucesso){
      this.exibirMensagemSucesso('Resposta Enviadas com Sucesso!')
    }else{
      this.exibirMensagemErro('Erro ao inserir Registro')
    }
  }
  public onBack() {
    this.submit = false;
    this.router.navigateByUrl(`/unidades-gestoras/${this.unidadeGestora}?aba=3`);
  }
  public async buscarItens(idTipoUniverso: number, idUG: number) {

    if (idTipoUniverso) {
      const res = await this.tipoUniversoService.obterItens(idTipoUniverso, idUG);
      if (res.sucesso) {
        this.modelTipoUniversoItens = res.dados;
        this.semDados = this.modelTipoUniversoItens.itens.length == 0;
        if (this.semDados) {
          this.novoRegistro = true;
        } else {
          this.novoRegistro = false;
        }

        this.reordenar();

        if (this.modelTipoUniversoItens && this.modelTipoUniversoItens.itens) {
          console.log('Adicionando controles ao FormGroup');
          this.modelTipoUniversoItens.itens.forEach(subtipo => {
            subtipo.itens.forEach(macro => {
              macro.itens.forEach(processo => {
                this.formGroup.addControl('resposta-' + processo.id, this.fb.control(''));
              });
            });
          });
        } else {
          console.warn('Dados não estão carregados.');
        }
      } else {
        this.exibirMensagemErro(res.mensagem.descricao);
      }
    }
  }

  onFinalizar(){

      Swal.fire({
        title: "Finalizar  Preenchimento",
        text: "Tem certeza que deseja finalizar o mapeamento do universo auditável?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Sim",
        cancelButtonText: "Não",
      }).then((res) => {
        if (res.value) {
          this.universoAuditavelService
            .finalizarUniversoAuditavel(this.universoAuditavel)
            .then((res) => {
              if (res.sucesso) {
                Swal.fire({
                  toast: true,
                  position: 'top-end',
                  showConfirmButton: false,
                  timer: 5000,
                  timerProgressBar: true,
                  icon: 'success',
                  text: res.mensagem.descricao,
                });
              } else {
                {
                  Swal.fire({
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 5000,
                    timerProgressBar: true,
                    icon: 'warning',
                    text: res.mensagem.descricao,
                  });
                }
              }
            })
            .catch((err) => {
              {
                Swal.fire({
                  toast: true,
                  position: 'top-end',
                  showConfirmButton: false,
                  timer: 5000,
                  timerProgressBar: true,
                  icon: 'error',
                  text: err.mensagem.descricao,
                });
              }
            });
        }
      });

  }
  isHabilitaBotaoFinalizar(){
    return this.habilitaBotaoFinalizar;
  }
  public reordenar(){
    this.modelTipoUniversoItens.itens.forEach((item, idx) => {
      item.ordem = (idx + 1);
      item.ordemExibicao = `1.${item.ordem.toString()}`;
      if(item.itens && item.itens.length > 0){
        this.fnReordenacao(item);
      }
    });
  }
  public fnReordenacao(item: ITipoUniversoItemModel){
    item.itens.forEach((subItem, subIdx) => {

      subItem.ordem = subIdx + 1;
      subItem.ordemExibicao = `${item.ordemExibicao}.${subItem.ordem.toString()}`
      if(subItem.itens && subItem.itens.length > 0){
        this.fnReordenacao(subItem);
      }
    });
  }
}
